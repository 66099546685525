import {
  Button,
  Card,
  CardContent,
  Fab,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Avatar from "react-avatar";
import AddSharpIcon from "@mui/icons-material/AddSharp";
import { useNavigate } from "react-router-dom";
import { getlatestOnboardedDealers } from "../../services/dealerServices";
const LatestOnboardingCard = (props) => {
  const navigate = useNavigate();
  const [dealers, setDealers] = useState([]);
  const { sx } = props;
  useEffect(() => {
    getlatestOnboardedDealers()
      .then(
        (res) => {
          let data = res.data;
          if (data && data.length > 0) {
            setDealers(data);
          }
        },
        (err) => {
          console.log("LatestOnbarding", err);
        }
      )
      .catch((err) => {
        console.error("Error LatestOnbarding Card", err);
      });
  }, []);

  const handleNewOnbaord = () => {
    navigate("../onboarding");
  };

  const handleOnbaordList = () => {
    navigate("../onboardinghome");
  };
  return (
    <Card sx={sx} raised={true}>
      <CardContent sx={{ p: 1 }}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} lg={12}>
            <Typography variant="subtitle1" component="div">
              New Onboarding
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} lg={12}>
            <Grid container spacing={1}>
              {dealers
                .map((item, index) => (
                  <Grid item xs={2} sm={2} lg={2} key={item.dealerId}>
                    <Stack alignItems="center" spacing={1}>
                      <Avatar
                        name={item.dealerName.replace(/[^a-zA-Z ]/g, "")}
                        maxInitials={2}
                        size="50"
                        round={true}
                        alt={item.dealerName}
                        title={item.dealerName}
                      />
                      <Typography
                        variant="label"
                        noWrap={false}
                        sx={{
                          fontSize: "11px",
                          color: "#404040",
                          maxWidth: "5em",
                        }}
                      >
                        {item.dealerName.length > 6
                          ? `${item.dealerName.substring(0, 6)}...`
                          : item.dealerName}
                      </Typography>
                    </Stack>
                  </Grid>
                ))
                .reverse()}
              <Grid item xs={2} sm={2} lg={2}>
                <Fab
                  color="secondary"
                  aria-label="new onboard"
                  onClick={handleNewOnbaord}
                >
                  <AddSharpIcon />
                </Fab>
              </Grid>
              <Grid item xs={12} sm={12} lg={12}>
                <Button
                  variant="contained"
                  color="secondary"
                  size="small"
                  fullWidth
                  sx={{
                    borderRadius: "50px",
                    marginTop: 1,
                  }}
                  onClick={handleOnbaordList}
                >
                  View Onboarded Dealers
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default LatestOnboardingCard;
