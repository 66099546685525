import axios from "./configs/axiosConfig";

export const userSignup = async (userData) => {
  const url = `/signup`;
  return await axios.post(url, userData);
};
export const userPasswordChange = async (pwdData) => {
  const url = `/changepassword`;
  return await axios.post(url, pwdData);
};

export const userPasswordReset = async (pwdData) => {
  const url = `/resetpassword`;
  return await axios.post(url, pwdData);
};
