import { Box, Chip } from "@mui/material";
import React from "react";
import {
  DataGrid,
  GridActionsCellItem,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import PasswordIcon from "@mui/icons-material/Password";

import JBWDateLabel from "./JBWDateLabel";
import Avatar from "react-avatar";
import CustomNoRowsOverlay from "./gridNoRowOverLay";

const UsersListGrid = ({ editUser, onPasswordChange, usersList }) => {
  const columns = [
    {
      field: "dealerAvatar",
      headerName: "Dealer Image",
      width: 110,
      renderCell: (param) => {
        return (
          <Avatar
            name={param.value.replace(/[^a-zA-Z ]/g, "")}
            maxInitials={2}
            size="60"
            round={true}
            style={{ marginTop: "5px", marginBottom: "5px" }}
          />
        );
      },
      sortable: false,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "dealerName",
      headerName: "Dealer Name",
      width: 180,
      sortable: false,
      headerAlign: "center",
      align: "left",
    },
    {
      field: "dealerId",
      headerName: "Dealer ID",
      width: 100,
      sortable: false,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "registeredName",
      headerName: "Registered Name",
      width: 200,
      sortable: false,
      headerAlign: "center",
      align: "left",
    },
    {
      field: "registeredEmail",
      headerName: "Registered Email",
      width: 180,
      sortable: false,
      headerAlign: "center",
      align: "left",
    },
    {
      field: "userRole",
      headerName: "Role",
      width: 100,
      sortable: false,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "createdDate",
      headerName: "Created Date",
      width: 130,
      sortable: false,
      renderCell: (param) => {
        return (
          <JBWDateLabel inputDate={param.value} dateFormat="MMM.DD,YYYY" />
        );
      },
      headerAlign: "center",
      align: "center",
    },
    {
      field: "isActive",
      headerName: "Status",
      width: 140,
      headerAlign: "center",
      align: "center",
      renderCell: (param) => {
        const bgColor = param.value === 0 ? "#DEB729" : "#4ECC72";
        //console.log("inside active colums", param);
        return (
          <Chip
            sx={{
              color: "#fff",
              backgroundColor: bgColor,
              mt: 1,
              mb: 1,
              width: "70%",
            }}
            label={param.value === 0 ? "InActive" : "Active"}
          />
        );
      },
      sortable: false,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      cellClassName: "actions",
      width: 90,
      headerAlign: "center",
      align: "center",
      sortable: false,
      getActions: (param) => {
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEdit(param)}
            color="primary"
            title="Edit User"
          />,
          <GridActionsCellItem
            icon={<PasswordIcon />}
            label="Change Password"
            onClick={handleChangePassword(param)}
            color="secondary"
            title="Change Password"
          />,
        ];
      },
    },
  ];

  const handleEdit = (param) => () => {
    console.log("Edit clicked ", param.row);
    editUser(param.row);
  };

  const handleChangePassword = (param) => () => {
    console.log("change password clicked ", param.row);
    onPasswordChange(param.row);
  };

  const QuickSearchToolbar = () => {
    return (
      <Box
        sx={{
          p: 0.5,
          pb: 0,
        }}
      >
        <GridToolbarQuickFilter />
      </Box>
    );
  };
  return (
    <DataGrid
      rows={usersList}
      getRowId={(row) => row.id}
      columns={columns}
      autoHeight
      getRowHeight={() => "auto"}
      disableColumnFilter={true}
      disableColumnMenu={true}
      slots={{
        noRowsOverlay: CustomNoRowsOverlay,
        toolbar: QuickSearchToolbar,
      }}
      pageSizeOptions={[10]}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: 10,
          },
        },
      }}
      sx={{
        "& .muidatagrid-colcelltitle ": {
          display: "block",
          textAlign: "center",
          width: "100%",
        },
      }}
    />
  );
};

export default UsersListGrid;
