import { Card, CardContent, Grid, Typography } from "@mui/material";
import React from "react";

const AgmEquipmentCard = (props) => {
  const { sx, manufacturer, modelNo, serialNumber } = props;
  return (
    <Card sx={sx} raised={true}>
      <CardContent sx={{ p: 1, ml: 2 }}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography
              color="text.secondary"
              variant="caption"
              align="left"
              sx={{ fontSize: "14px" }}
            >
              Manufacturer :{manufacturer}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              color="text.secondary"
              variant="caption"
              align="left"
              sx={{ fontSize: "14px" }}
            >
              Model Number :{modelNo}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              color="text.secondary"
              variant="caption"
              align="left"
              sx={{ fontSize: "14px" }}
            >
              Serial Number : {serialNumber}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default AgmEquipmentCard;
