import CryptoJS from "crypto-js";

const secret_key = "JBW-APP-SECRET-KEY-123";

export const encryptData = (data) => {
  return CryptoJS.AES.encrypt(JSON.stringify(data), secret_key).toString();
};

export const decryptData = (data) => {
  var bytes = CryptoJS.AES.decrypt(data, secret_key);
  return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
};
