import { Done } from "@mui/icons-material";
import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../../AuthContext";
import { createNewOnboarding } from "../../services/dealerServices";

const CLientConnect = () => {
  let { onboarding, setOnboarding, setIsOnboarded, group, user } =
    useContext(AuthContext);
  console.log("connect Onboarding:", onboarding);
  let navigate = useNavigate();
  let urlLocation = useLocation();
  const [onBoardingErr, setOnBoardingErr] = useState("");
  const [disableOnboardBtn, setDisableOnboardBtn] = useState(false);

  const OnboardingHandler = () => {
    if (onboarding) {
      setDisableOnboardBtn(true);
      createNewOnboarding({ ...onboarding, createdBy: user.name })
        .then(
          (res) => {
            const data = res.data;
            if (data && !data.errorType) {
              setOnboarding(null);
              setIsOnboarded(true);
              if (group !== "jbw-dealer") {
                navigate("/home/overview");
              } else {
                navigate("/home/dealerdetails");
              }
            } else {
              setOnBoardingErr("Unable to onboard new tenant");
            }
          },
          (err) => {
            console.log("newOnbaording error :", err);
            setOnBoardingErr("error in new onboarding");
          }
        )
        .catch((err) => {
          console.log("Onbaording error :", err);
          setOnBoardingErr("Internal server error");
        });
    }
  };
  const editHandler = () => {
    navigate("/home/onboarding/clientid", {
      state: { key: urlLocation.state?.key },
    });
  };
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={{ minHeight: "80vh" }}
    >
      <Grid item xs={3}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{ p: 2, border: "1px solid #bdbdbd", borderRadius: "50px" }}
        >
          <Done color="success" sx={{ fontSize: 100 }} />
        </Box>
      </Grid>
      <Grid item xs={3}>
        <Typography variant="h3" component="h3">
          Success!
        </Typography>
      </Grid>
      <Grid item xs={3} sx={{ mt: 2 }}>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Typography
            variant="body2"
            component="div"
            sx={{ color: "#a8a8a8d4" }}
          >
            Your details were successfully validated
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={3}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{
            width: 450,
            padding: 1,
          }}
        ></Box>
      </Grid>
      <Grid item xs={12}>
        <Box
          sx={{
            padding: 1,
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Button
            variant="outlined"
            color="primary"
            size="small"
            sx={{
              borderRadius: "50px",
              mr: 2,
            }}
            onClick={editHandler}
          >
            edit
          </Button>
          <Button
            variant="contained"
            color="success"
            size="small"
            sx={{
              borderRadius: "50px",
            }}
            onClick={OnboardingHandler}
            disabled={disableOnboardBtn}
          >
            Onboard
          </Button>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body" sx={{ color: "#f44336", fontSize: "10px" }}>
          {onBoardingErr}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default CLientConnect;
