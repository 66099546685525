import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fab,
  Grid,
  IconButton,
  Stack,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import Tab from "@mui/material/Tab";
import { useState } from "react";
import { useEffect } from "react";
import IconTextField from "./IconTextField";
import CloseIcon from "@mui/icons-material/Close";
import AddBoxIcon from "@mui/icons-material/AddBox";
import CancelIcon from "@mui/icons-material/Cancel";

const ConfirmDialog = (props) => {
  console.log("confirm dialog called");
  const { open, onClose, equipmentRemoveList } = props;

  const handleClose = (event, reason) => {
    if (reason === "escapeKeyDown" || reason === "backdropClick") {
      return;
    }
    onClose(false);
  };

  const handleAgree = () => {
    onClose(true);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Alert"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {`If you're attempting to remove ${equipmentRemoveList.length} Equipments, canceling the edit will not have any effect. To proceed with removing the equipments, please click on 'No' and then click on 'Save'. Otherwise, click on 'Proceed' to cancel the edit.`}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>No</Button>
        <Button onClick={handleAgree} autoFocus>
          Proceed
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const EquipmentForm = ({
  equipment,
  onEquimentChange,
  formSubmitted = false,
}) => {
  const [equipmentId, setEquipmentId] = useState("");
  const [manufacturer, setManufacturer] = useState("");
  const [modelNo, setModelNo] = useState("");
  const [serialNumber, setSerialNumber] = useState("");
  const [changeEquipment, setChangeEquipment] = useState(null);
  useEffect(() => {
    //console.log("equipment form :", equipment);
    if (equipment) {
      setChangeEquipment(equipment);
      setEquipmentId(equipment.id);
      setManufacturer(equipment.manufacturer);
      setModelNo(equipment.modelNo);
      setSerialNumber(equipment.serialNumber);
    }
  }, [equipment]);

  const formInputChange = (e) => {
    if (e.target.name === "manufacturer") {
      setManufacturer(e.target.value);
    }
    if (e.target.name === "modelNo") {
      setModelNo(e.target.value);
    }
    if (e.target.name === "serialNumber") {
      setSerialNumber(e.target.value);
    }
    setChangeEquipment((values) => ({
      ...changeEquipment,
      [e.target.name]: e.target.value,
    }));
  };

  const onEquValueChange = () => {
    onEquimentChange({ ...changeEquipment, id: equipmentId });
  };

  return (
    <>
      {/* {`${equipmentId}, ${manufacturer},${modelNo},${serialNumber}`} */}
      <Grid
        container
        spacing={2}
        direction="column"
        justifyContent="center"
        alignItems="stretch"
      >
        <Grid item xs={6} md={12}>
          <IconTextField
            fullWidth
            variant="outlined"
            margin="normal"
            autoFocus
            required
            id="manufacturer"
            name="manufacturer"
            label="Manufacturer"
            autoComplete="manufacturer"
            value={manufacturer}
            onChange={formInputChange}
            onBlur={onEquValueChange}
            disabled={formSubmitted ? true : false}
            size="small"
          />
        </Grid>
        <Grid item xs={6} md={12}>
          <IconTextField
            fullWidth
            variant="outlined"
            margin="normal"
            id="modelNo"
            label="Model No"
            name="modelNo"
            required
            autoComplete="modelNo"
            value={modelNo}
            onChange={formInputChange}
            onBlur={onEquValueChange}
            disabled={formSubmitted ? true : false}
            size="small"
          />
        </Grid>
        <Grid item xs={6} md={12}>
          <IconTextField
            fullWidth
            variant="outlined"
            margin="normal"
            id="serialNumber"
            label="Serial No"
            name="serialNumber"
            required
            autoComplete="serialNumber"
            value={serialNumber}
            onChange={formInputChange}
            onBlur={onEquValueChange}
            disabled={formSubmitted ? true : false}
            size="small"
          />
        </Grid>
        {/* <Grid item xs={6} md={12}>
          <Fab
            variant="circular"
            size="small"
            color="error"
            onClick={handleRemoveEquipment}
          >
            <CloseIcon />
          </Fab>
        </Grid> */}
      </Grid>
    </>
  );
};

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  //console.log("custome tab panel :", children);

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`equipment-tabpanel-${index}`}
      aria-labelledby={`equipment-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 1 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `equipment-tab-${index}`,
    "aria-controls": `equipment-tabpanel-${index}`,
  };
}

const AgreementEquipmentForm = ({
  equipmentList,
  onClose,
  onSave,
  formStatus = false,
  optype,
}) => {
  //console.log("equipment form :", equipmentList);
  const [value, setValue] = useState(0);
  const [changeEquipmentList, setChangeEquipmentList] = useState([]);
  const [formSubmitted, setFormSubmitted] = useState(formStatus);
  const [equError, setEquError] = useState("");
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [removedEquipmentList, setRemovedEquipmentList] = useState([]);

  const addNewEquipment = (eqid) => {
    const newEq = {
      id: eqid,
      manufacturer: "",
      modelNo: "",
      serialNumber: "",
      isDeleted: false,
      eqtype: "new",
    };
    return newEq;
  };

  const validateEquipment = (eqtabid) => {
    console.log("validating optype :", optype);
    console.log("validating tab :", eqtabid);
    console.log("validating changeEquList :", changeEquipmentList);
    setEquError("");
    if (eqtabid === "-1") {
      if (optype === "update") {
        const filteredList = changeEquipmentList.filter((eq) => {
          console.log(
            `validating equi data : manufacturer :${eq.manufacturer} , model no :${eq.modelNo} , serial no :${eq.serialNumber}`
          );
          //debugger;
          return !(
            (eq.manufacturer !== null || eq.manufacturer === "") &&
            (eq.modelNo !== null || eq.modelNo === "") &&
            (eq.serialNumber !== null || eq.serialNumber === "")
          );
        });
        //debugger;
        return filteredList;
      } else {
        const filteredList = changeEquipmentList.filter((eq) => {
          console.log(
            `validating equi data : manufacturer :${eq.manufacturer} , model no :${eq.modelNo} , searial no :${eq.serialNumber}`
          );
          // debugger;
          return !(
            eq.manufacturer !== "" &&
            eq.modelNo !== "" &&
            eq.serialNumber !== ""
          );
        });
        return filteredList;
      }
    } else {
      return changeEquipmentList
        .filter((eq) => eq.id === eqtabid)
        .filter(
          (eq) =>
            (eq.manufacturer === "" || eq.manufacturer === null) &&
            (eq.modelNo === "" || eq.modelNo === null) &&
            (eq.serialNumber === "" || eq.serialNumber === null)
        );
    }
  };

  useEffect(() => {
    setFormSubmitted(false);
    if (optype === "update" && equipmentList && equipmentList.length > 0) {
      setChangeEquipmentList(equipmentList);
    } else {
      setChangeEquipmentList((values) => [addNewEquipment(1)]);
    }
  }, [equipmentList, optype]);

  const handleChange = (event, newValue) => {
    console.log("handleChange event :", event);
    console.log("handleChange :", newValue);
    setValue(newValue);
  };

  const eqTabs = () => {
    if (changeEquipmentList.length > 0) {
      //let index = 0;
      return changeEquipmentList
        .filter((eq) => !eq.isDeleted)
        .map((eq, index) => {
          const tabs = (
            <Tab
              label={
                <span>
                  {`Equipment - ${index + 1}`}
                  <Tooltip title={`Remove Equipment - ${index + 1}`}>
                    <IconButton
                      variant="contained"
                      color="warning"
                      sx={{ mb: 2, ml: 1, height: 2, width: 2 }}
                      onClick={(e) => {
                        e.stopPropagation();
                        //alert("Closing this tab...");
                        console.log("removing tab", eq);
                        handleEquipmentRemove(eq, index);
                      }}
                    >
                      <CancelIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </span>
              }
              key={index}
              {...a11yProps(index)}
            />
          );

          //index++;
          return tabs;
        });
    }
  };
  const eqTabsContent = () => {
    if (changeEquipmentList.length > 0) {
      //let index = 0;
      return changeEquipmentList
        .filter((eq) => !eq.isDeleted)
        .map((eq, index) => {
          //console.log("equipment tabs content:", eq);
          const tabContent = (
            <CustomTabPanel value={value} key={eq.id} index={index}>
              <Box
                sx={{
                  width: 600,
                  padding: 1,
                }}
              >
                <EquipmentForm
                  equipment={eq}
                  onEquimentChange={handleEquipmentChange}
                />
              </Box>
            </CustomTabPanel>
          );
          //index++;
          return tabContent;
        });
    }
  };

  const handleEquipmentRemove = (eqValue, index) => {
    console.log("equipment value remove", eqValue);
    if (equipmentList && equipmentList.length > 0) {
      const obj = equipmentList.find((eql) => eql.id === eqValue.id);
      console.log("equipment value remove object found in main list ", obj);
      if (obj) {
        console.log("creating equipment remove list ", obj);
        setRemovedEquipmentList((value) => [
          ...value,
          { ...obj, isDeleted: 1 },
        ]);
      }
    }

    if (changeEquipmentList && changeEquipmentList.length > 0) {
      //setValue((prev) => 0);
      //handleChange(null, 1);
      const chgEqList = changeEquipmentList.filter(
        (eq) => eq.id !== eqValue.id
      );
      setChangeEquipmentList((values) => chgEqList);
      console.log("change equipment list", changeEquipmentList);

      eqTabs();
      eqTabsContent();
      handleChange(null, index - 1);
    }
  };

  const handleEquipmentChange = (eqValue) => {
    console.log("equipment value change", eqValue);
    if (changeEquipmentList && changeEquipmentList.length > 0) {
      const chgEqList = changeEquipmentList.map((eq) =>
        eq.id === eqValue.id
          ? {
              ...eq,
              manufacturer: eqValue.manufacturer,
              modelNo: eqValue.modelNo,
              serialNumber: eqValue.serialNumber,
            }
          : eq
      );
      setChangeEquipmentList((values) => chgEqList);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "escapeKeyDown" || reason === "backdropClick") {
      return;
    }
    if (removedEquipmentList.length > 0) {
      setConfirmOpen(true);
    } else {
      closePopup(event);
    }
  };

  const closePopup = (event) => {
    setFormSubmitted(false);
    setRemovedEquipmentList([]);
    onClose(event);
  };

  const handleSave = () => {
    if (changeEquipmentList.length === 0) {
      setEquError("Please add Atleast one New Equipment.");
      return;
    }
    setFormSubmitted(true);
    const validEqList = validateEquipment("-1");
    console.log("valid eq list :", validEqList);
    if (validEqList && validEqList.length > 0) {
      let errorEq = "";
      validEqList.forEach((eq) => {
        errorEq.concat("Equipment - ", eq.id, ", ");
      });
      setEquError(
        `Please add manufacturer, model no and Serial Number for all the equipment on the agreement. `
      );
      setFormSubmitted(false);
      return;
    }
    if (removedEquipmentList.length > 0) {
      const finalEqlist = changeEquipmentList.concat(removedEquipmentList);
      console.log("removed eqiupment list adding to change list ", finalEqlist);

      onSave(finalEqlist);
    } else {
      console.log(
        "final change equipment list with out deleted equipment",
        changeEquipmentList
      );
      onSave(changeEquipmentList);
    }
  };

  const handleAddNewTab = (e) => {
    setEquError("");
    if (changeEquipmentList.length < 5) {
      setChangeEquipmentList((values) => [
        ...values,
        addNewEquipment(values.length + 1),
      ]);
    }
    setValue(changeEquipmentList.length);
  };

  const handleConfirmClose = (status) => {
    if (status === true) {
      closePopup();
    }
    setConfirmOpen(false);
  };
  useEffect(() => {
    console.log("tab value :", value);
  }, [value]);

  return (
    <Box sx={{ width: "100%" }} component="form" noValidate>
      <ConfirmDialog
        open={confirmOpen}
        equipmentRemoveList={removedEquipmentList}
        onClose={handleConfirmClose}
      />
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="Equipments Tabs"
          indicatorColor="secondary"
        >
          {eqTabs()}
          {changeEquipmentList.length < 5 ? (
            <IconButton color="secondary" onClick={handleAddNewTab}>
              <AddBoxIcon size="medium" />
            </IconButton>
          ) : (
            ""
          )}
        </Tabs>
      </Box>
      {eqTabsContent()}
      {changeEquipmentList.length === 0 ? (
        <Box
          sx={{
            width: 600,
            pt: 5,
            pb: 5,
          }}
        >
          <Typography variant="subtitle1" component="span">
            Please click on <AddBoxIcon size="medium" color="secondary" /> to
            add new Equipment.
          </Typography>
        </Box>
      ) : (
        ""
      )}
      <Box
        sx={{
          borderTop: 1,
          borderColor: "divider",
        }}
      >
        <Typography variant="body" sx={{ color: "#f44336", fontSize: "13px" }}>
          {equError}
        </Typography>
        {changeEquipmentList.length > 5 && (
          <Typography
            variant="body"
            sx={{ color: "#f44336", fontSize: "13px" }}
          >
            Only 5 equipment records are allowed for the agreement.
          </Typography>
        )}
        <Box sx={{ flexGrow: 1 }} />
        <Box
          p={1}
          display="flex"
          justifyContent="flex-end"
          alignItems="flex-end"
        >
          <Button
            disabled={formSubmitted ? true : false}
            onClick={handleClose}
            color="warning"
          >
            Cancel
          </Button>

          <Button
            disabled={
              formSubmitted || changeEquipmentList.length > 5 ? true : false
            }
            onClick={handleSave}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default AgreementEquipmentForm;
