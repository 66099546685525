import axios from "axios";

//const BASE_URL = process.env.AWS_GATEWAY_BASE_URL;
const BASE_URL = "https://eqwvdsbq5c.execute-api.us-east-1.amazonaws.com/dev";
const app = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
    "Access-Control-Allow-Headers": "*",
    "Content-Type": "application/json",
  },
  withCredentials: false,
});

export default app;
