import React, { useEffect, useState } from "react";
import {
  Alert,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import WarningIcon from "@mui/icons-material/Warning";

const JBWClaimsIframDialog = ({
  dialogOpen = false,
  onClose,
  dealerId,
  agreementId,
}) => {
  const [iframeData, setIframeData] = useState("");
  const [agreementIdList, setAgreementIdList] = useState([]);
  const [selectedAgreementId, setSelectedAgreementId] = useState();

  useEffect(() => {
    if (agreementId) {
      const _temAgreementIds = agreementId.split(",");
      console.log("jbwIframe temAgreementList :", _temAgreementIds);
      if (_temAgreementIds.length > 1) {
        setAgreementIdList(_temAgreementIds);
      } else {
        setSelectedAgreementId(_temAgreementIds[0]);
      }
    }
  }, [agreementId]);

  useEffect(() => {
    const handleMessage = (event) => {
      if (event.origin !== "https://www.jbandassociates.biz") return;

      //console.log("event", event);
      //console.log("event data :", event.data);
      if (event.data) {
        setIframeData(JSON.parse(event.data));
      }
    };

    window.addEventListener("message", handleMessage);

    // Don't forget to remove addEventListener
    return () => window.removeEventListener("message", handleMessage);
  }, []);

  const handleClose = (event, reason) => {
    if (reason === "escapeKeyDown" || reason === "backdropClick") {
      return;
    }
    //{"result":"processing","claimid":"12345","agreementid":"234567"}

    console.log("Claim Result :", iframeData.result);
    console.log("claim id : ", iframeData.claimid);
    console.log("claim agreement id : ", iframeData.agreementid);
    setAgreementIdList([]);
    setSelectedAgreementId();
    onClose(iframeData);
  };

  const handleChange = (event) => {
    setSelectedAgreementId(event.target.value);
    setAgreementIdList([]);
  };

  return (
    <>
      <Dialog
        open={dialogOpen}
        disableEscapeKeyDown
        PaperProps={{
          component: "div",
        }}
        fullWidth={agreementIdList && agreementIdList.length > 0 ? false : true}
        maxWidth={agreementIdList && agreementIdList.length > 0 ? "md" : "lg"}
      >
        <DialogTitle>
          {agreementIdList && agreementIdList.length > 0
            ? "Select JBW Agreement ID "
            : "JBW Create Claim"}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          {agreementIdList && agreementIdList.length > 0 ? (
            <Box
              component="form"
              sx={{ display: "flex", flexWrap: "wrap", margin: "auto" }}
            >
              <Alert
                icon={<WarningIcon fontSize="inherit" />}
                severity="warning"
              >
                The selected location has multiple agreements. Please select the
                agreement Id against which the claim needs to be logged.
              </Alert>
              <FormControl variant="standard" sx={{ m: 2, minWidth: 160 }}>
                <InputLabel id="agreementid-select-standard-label">
                  JB Agreement ID
                </InputLabel>
                <Select
                  labelId="agreementid-select-standard-label"
                  id="agreementid-select-standard"
                  value={selectedAgreementId}
                  onChange={handleChange}
                  label="JB Agreement ID"
                  autoWidth
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {agreementIdList.map((aid) => {
                    return <MenuItem value={aid}>{aid}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            </Box>
          ) : (
            <iframe
              src={`https://www.jbandassociates.biz/member/service/jb360claim.aspx?agreementid=${selectedAgreementId}`}
              title="jbwclaims"
              height="550px"
              width="100%"
              id="jb360-claim-id"
            />
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default JBWClaimsIframDialog;
