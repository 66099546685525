import axios from "./configs/axiosConfig";

export const verifyDealer = async (id) => {
  const url = `/dealer/${id}/verify`;
  return await axios.get(url);
};

export const checkDealerOnboard = async (id) => {
  const url = `/dealer/${id}/onboard`;
  return await axios.get(url);
};

export const updateDealerRegisterStatus = async (id) => {
  const url = `/dealer/${id}`;
  return await axios.patch(url);
};

export const createNewOnboarding = async (newOnboarding) => {
  const url = `newdealeronboard`;
  return await axios.post(url, newOnboarding);
};

export const getNonOnboardedDealers = async () => {
  const url = `/dealer/nononboardedlist`;
  return await axios.get(url);
};
export const getlatestOnboardedDealers = async () => {
  const url = `/dealer/latestonboardeddealers`;
  return await axios.get(url);
};

export const getDealerDetailsCardData = async (id) => {
  const url = `/dealer/${id}/cardsdata`;
  return await axios.get(url);
};

export const getTransmissionDetailsByDealer = async (id) => {
  const url = `/dealer/${id}/transmissiondetails`;
  return await axios.get(url);
};
export const getTransmissionDetailsHistoryByDealer = async (id) => {
  const url = `/dealer/${id}/transmissiondetailshistory`;
  return await axios.get(url);
};

export const getTenantInfo = async (id) => {
  const url = `/dealer/${id}/gettenantinfo`;
  return await axios.get(url);
};

export const getAllDealers = async () => {
  const url = `/dealer`;
  return await axios.get(url);
};
