import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import UsersListGrid from "../../components/usersListGrid";
import UserRegistrationDialog from "../../components/UserRegistrationDialog";
import ResetPasswordDialog from "../../components/ResetPasswordDialog";
import { getDealerRegistrationList } from "../../services/dealerRegisterServices";

const UsersCard = (props) => {
  // const { sx } = props;
  const [openRegDialog, setOpenRegDialog] = useState(false);
  const [openPwdDialog, setOpenPwdDialog] = useState(false);
  const [chgPwdEmail, setChgPwdEmail] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [userData, setUserData] = useState("");

  const [usersList, setUserList] = useState([]);

  const getUsersList = () => {
    getDealerRegistrationList()
      .then(
        (res) => {
          const data = res.data;
          if (data && data.length > 0) {
            const tempUserList = data.map((user) => {
              //console.log("usercard load uuser :", user);
              return {
                id: user.id,
                dealerName: user.dealerName ? user.dealerName : "Admin User",
                dealerId: user.dealerId === 0 ? "" : user.dealerId,
                dealerAvatar: user.dealerAvatar,
                isActive: user.isActive,
                registeredName: user.registeredName,
                registeredEmail: user.registeredEmail,
                userRole: user.dealerId ? "Dealer" : "Admin",
                createdDate: user.createdOn,
              };
            });
            setUserList(tempUserList);
          }
        },
        (err) => {
          console.log("users list data loading error :", err);
        }
      )
      .catch((err) => {
        console.error("user list server error", err);
      });
  };

  useState(() => {
    getUsersList();
  }, []);

  const handleRegDialogClose = (data) => {
    if (data === "userCreated") {
      getUsersList();
    }
    setOpenRegDialog(false);
  };

  const handleRegDialogOpen = () => {
    setIsEdit(false);
    setOpenRegDialog(true);
  };

  const handlePwdDialogClose = (e) => {
    //console.log("pwd dialog close :", e);
    setIsEdit(false);
    setOpenPwdDialog(false);
  };

  const handlePwdDialogOpen = () => {
    setOpenPwdDialog(true);
  };

  const handleEditUser = (data) => {
    //console.log("userCard  Edit:", data);
    const {
      id,
      dealerId,
      dealerName,
      registeredName,
      registeredEmail,
      isActive,
      userRole,
    } = data;
    const editUser = {
      id: id,
      dealerId: dealerId,
      companyName: dealerName,
      fullName: registeredName,
      email: registeredEmail,
      roleValue: userRole,
      isActive: isActive,
    };
    setUserData(editUser);
    setIsEdit(true);
    setOpenRegDialog(true);
  };
  const handlePasswordChange = (data) => {
    console.log("userCard  change password :", data);
    setChgPwdEmail(data.registeredEmail);
    handlePwdDialogOpen();
  };

  return (
    <Card sx={{ borderRadius: 3 }} raised={true}>
      <CardContent sx={{ p: 2 }}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} lg={12}>
            <UserRegistrationDialog
              open={openRegDialog}
              onClose={handleRegDialogClose}
              isEdit={isEdit}
              userData={userData}
            />
            <ResetPasswordDialog
              open={openPwdDialog}
              onClose={handlePwdDialogClose}
              registeredEmail={chgPwdEmail}
            />
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} lg={12}>
                <Box
                  sx={{
                    width: "100%",
                    padding: 1,
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <Typography variant="h6" component="h6">
                    All Users <br />
                    {/* <Typography
                      variant="body2"
                      component="div"
                      sx={{ color: "#a8a8a8d4" }}
                    >
                      Showing 5 out of 5
                    </Typography> */}
                  </Typography>
                  <Box sx={{ flexGrow: 1 }} />
                  <Box>
                    <Button
                      variant="contained"
                      color="secondary"
                      size="small"
                      sx={{
                        borderRadius: "50px",
                      }}
                      onClick={handleRegDialogOpen}
                    >
                      Add New User
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} lg={12}>
            <UsersListGrid
              editUser={handleEditUser}
              onPasswordChange={handlePasswordChange}
              usersList={usersList}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default UsersCard;
