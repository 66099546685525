import React, { useContext, useEffect, useState } from "react";
import {
  getAgreementDetails,
  createDupilcateAgreement,
} from "../services/agreementServices";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import AgreementDetails from "./AgreementDetails";
import { AuthContext } from "../AuthContext";

const Defaul_Load_Msg = " Please Wait while Loading Agreement Details ....";
const AgreementDetailsDialog = ({
  openAgmDetailsDialog,
  agreementId,
  onCloseAgmDetailsDialog,
}) => {
  const { user } = useContext(AuthContext);
  const [agreementDetails, setAgreementDetails] = useState(null);
  const [loading, setLoading] = useState(Defaul_Load_Msg);
  const [disableSaveBtn, setDisableSaveBtn] = useState(false);
  const [disableCancelBtn, setDisableCancelBtn] = useState(false);
  const [saveError, setSaveError] = useState("");
  const [deletedEquipments, setDeletedEquipments] = useState([]);

  useEffect(() => {
    if (agreementId) {
      setDisableSaveBtn(true);
      setLoading(Defaul_Load_Msg);
      setSaveError("");
      getAgreementDetails(agreementId).then(
        (res) => {
          console.log(res);
          if (res.status === 200) {
            console.table(res.data);
            if (res.data) {
              setDisableSaveBtn(false);
              setDisableCancelBtn(false);
              setAgreementDetails(res.data);
              setLoading("");
            } else {
              setLoading("No Agreement Details availiable");
            }
          } else {
            setLoading("Unable to load Agreement Details");
          }
        },
        (err) => {
          setLoading("Error loading agreement details data");
          console.error("Error loading agreement details data");
        }
      );
    }
  }, [agreementId]);

  const handleClose = (event, reason) => {
    if (reason === "escapeKeyDown" || reason === "backdropClick") {
      return;
    }
    setAgreementDetails(null);
    setSaveError("");
    setLoading("");
    onCloseAgmDetailsDialog();
  };

  const handleCreate = () => {
    setDisableSaveBtn(true);
    setDisableCancelBtn(true);
    setSaveError("");
    setLoading("Please wait while creating Duplicate Agreement");
    const tempAgreementDetails = agreementDetails;
    setAgreementDetails(null);
    createDupilcateAgreement(agreementId, {
      agreementDetails:
        tempAgreementDetails &&
        tempAgreementDetails.equipments &&
        tempAgreementDetails.equipments.length > 0
          ? tempAgreementDetails.equipments.map((ag) => ag.id).join(",")
          : "",
      createdBy: user.name,
    }).then(
      (res) => {
        if (res.status === 200) {
          console.log("Duplicate Agreement id :", res.data.agreementId);
          setLoading("Duplicate Agreement Created Successfully");
          onCloseAgmDetailsDialog("success");
        } else {
          setDisableSaveBtn(false);
          setDisableCancelBtn(false);
          setSaveError("Unable to create Duplicate Agreement");
          setLoading("");
          setAgreementDetails(tempAgreementDetails);
        }
      },
      (err) => {
        setDisableSaveBtn(false);
        setDisableCancelBtn(false);
        setLoading("");
        setAgreementDetails(tempAgreementDetails);
        setSaveError("Unable to create Duplicate Agreement");
      }
    );
  };
  const handleDeletedEquipment = (params) => {
    console.log("deleting agreements called in dialog :", params);
    const equipments = agreementDetails.equipments.filter(
      (eq) => eq.id !== params.id
    );
    setAgreementDetails((oldValue) => ({
      ...oldValue,
      equipments: [...equipments],
    }));
  };

  return (
    <Dialog
      onClose={handleClose}
      open={openAgmDetailsDialog}
      PaperProps={{
        component: "div",
      }}
      fullWidth={true}
      maxWidth={"lg"}
      disableEscapeKeyDown
    >
      <DialogTitle>Agreement details</DialogTitle>
      <DialogContent>
        {agreementDetails ? (
          <AgreementDetails
            agreementDetails={agreementDetails}
            deletedEquipment={handleDeletedEquipment}
          />
        ) : (
          <Grid container>
            <Grid item xs={12} sx={{ maxHeight: "600px", maxWidth: "600px" }}>
              <Typography
                color="secondary"
                variant="caption"
                align="left"
                sx={{ fontSize: "14px" }}
              >
                {loading}
              </Typography>
            </Grid>
          </Grid>
        )}
        {saveError && (
          <Typography
            variant="body"
            sx={{ color: "#f44336", fontSize: "10px" }}
          >
            {saveError}
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCreate} disabled={disableSaveBtn}>
          Save
        </Button>
        <Button onClick={handleClose} disabled={disableCancelBtn}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AgreementDetailsDialog;
