import { Box, Button, Container, Grid, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ClientGrid from "../components/ClientGrid";
import { getDealerOnboardList } from "../services/dealeronboard";
import { getTenantInfo } from "../services/dealerServices";
import { AuthContext } from "../AuthContext";

const Onboarding = () => {
  const [onboardDealerList, setOnboardDealerList] = useState([]);
  const { setDealer } = useContext(AuthContext);
  useEffect(() => {
    setDealer(null);
    localStorage.removeItem("dealer");
  }, []);

  let navigate = useNavigate();
  const newDealerHandler = () => {
    navigate("/home/onboarding", { state: { key: "onboardinghome" } });
  };

  const getDealerOnboards = () => {
    getDealerOnboardList()
      .then(
        (res) => {
          const data = res.data;
          if (data && data.length > 0) {
            let syncData = localStorage.getItem("dealerSycState");
            //debugger;
            if (syncData && syncData.length > 0) {
              syncData = JSON.parse(syncData);
              const onlist = data.map((d) => {
                const dealerInde = syncData.findIndex(
                  (di) => di.dealerId === d.dealerId
                );
                if (dealerInde > -1) {
                  const syncTime = syncData[dealerInde].syncTime;
                  var diff =
                    (new Date().getTime() - new Date(syncTime).getTime()) /
                    1000;
                  diff /= 60;
                  const timeDiff = Math.abs(Math.round(diff));
                  return { ...d, syncStatus: timeDiff < 10 ? true : false };
                }
                return { ...d, syncStatus: false };
              });
              setOnboardDealerList(onlist);
            } else {
              const onlist = data.map((d) => ({ ...d, syncStatus: false }));
              setOnboardDealerList(onlist);
            }
          }
        },
        (err) => {
          console.log("error loading data", err);
        }
      )
      .catch((err) => {
        console.error("Error calling OnboardDealer List", err);
      });
  };

  useEffect(() => {
    getDealerOnboards();
  }, []);

  const syncTenantInfo = (dealerId) => {
    getTenantInfo(dealerId).then((res) => {
      console.log(res);
      let dealerSycState = localStorage.getItem("dealerSycState");
      if (dealerSycState && dealerSycState.length > 0) {
        dealerSycState = JSON.parse(dealerSycState);
        const ind = dealerSycState.findIndex((ds) => ds.dealerId === dealerId);
        if (ind === -1) {
          dealerSycState.push({ dealerId, syncTime: new Date() });
        } else {
          dealerSycState[ind] = { dealerId, syncTime: new Date() };
        }
        localStorage.setItem("dealerSycState", JSON.stringify(dealerSycState));
        return;
      }
      localStorage.setItem(
        "dealerSycState",
        JSON.stringify([{ dealerId, syncTime: new Date() }])
      );
      getDealerOnboards();
      return;
    });
  };

  const handleTenantSync = (param) => {
    syncTenantInfo(param.dealerId);
  };

  return (
    <Box component="main">
      <Container maxWidth="xl">
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} lg={12}>
            <Box
              sx={{
                maxWidth: "100%",
                padding: 2,
                boxShadow: 4,
                borderRadius: 3,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                backgroundColor: "#fff",
              }}
            >
              <Grid container>
                <Grid item xs={12}>
                  <Box
                    sx={{
                      maxWidth: "100%",
                      padding: 1,
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <Typography variant="h6" component="h6">
                      All Dealers <br />
                      <Typography
                        variant="body2"
                        component="div"
                        sx={{ color: "#a8a8a8d4" }}
                      ></Typography>
                    </Typography>
                    <Box sx={{ flexGrow: 1 }} />
                    <Box>
                      <Button
                        variant="contained"
                        color="secondary"
                        size="small"
                        sx={{
                          borderRadius: "50px",
                        }}
                        onClick={newDealerHandler}
                      >
                        Onboarding New Dealer
                      </Button>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      maxWidth: "100%",
                      padding: 1,
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <ClientGrid
                      onboardDealers={onboardDealerList}
                      onTenantSync={handleTenantSync}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Onboarding;
