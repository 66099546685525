import React, { useContext, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
} from "@mui/material";
import JBWDateLabel from "./JBWDateLabel";
import { updateAgeementMatchLocation } from "../services/agreementMatchDataService";
import { AuthContext } from "../AuthContext";
import STLocationSearch from "./STLocationSearch.jsx";

const STLocationSearchPopup = ({
  dialogOpen = false,
  agreement,
  onClose,
  onGridRefresh,
}) => {
  //const [open, setOpen] = React.useState(dialogOpen);
  const { user } = useContext(AuthContext);
  // const [selectedAgreement, setSelectedAgreement] = useState(null);
  // if (agreement) {
  //   setSelectedAgreement(agreement);
  // }
  const [searchType, setSearchType] = useState("address");
  const [searchValue, setSearchValue] = useState(null);
  const handleSearchTypeChange = (event) => {
    setSearchType(event.target.value);
    setSearchValue(null);
  };

  const [savingData, setSavingData] = useState(false);

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "left",
    color: theme.palette.text.secondary,
  }));

  // useEffect(() => {
  //   if (agreement) {
  //     setSelectedAgreement(agreement);
  //   }
  // }, [agreement]);

  const handleSaveLocation = async () => {
    setSavingData(true);
    const locationData = {
      locationId: searchValue.locationId,
      tenantId: searchValue.tenantId,
      customerName: searchValue.locationName,
      street: searchValue.street,
      unit: searchValue.unit,
      city: searchValue.city,
      state: searchValue.state,
      zip: searchValue.zip,
      modifiedBy: user.name,
      agreementId: agreement?.agreementId,
    };
    const response = await updateAgeementMatchLocation(
      agreement?.id,
      locationData
    );
    console.log("Update Response", response);
    if (response.data) {
      setSearchValue(null);
      setSearchType("address");
      setSavingData(false);
      onGridRefresh();
    } else {
      setSavingData(false);
    }
  };

  const handleLocationSelected = (location) => {
    console.log("location ", location);
    setSearchValue(location);
  };

  const handleClose = (event, reason) => {
    if (reason === "escapeKeyDown" || reason === "backdropClick") {
      return;
    }
    setSearchType("address");
    setSearchValue(null);
    setSavingData(false);
    onClose();
    //setOpen(false);
  };

  const jbwAgreementsTable = (agreement) => {
    return (
      <>
        {agreement ? (
          <TableContainer>
            <Table
              sx={{ minWidth: 550 }}
              size="small"
              aria-label="JBW Agreements table"
            >
              <TableHead sx={{ fontSize: "10px" }}>
                <TableRow>
                  <TableCell>Agreement Id</TableCell>
                  <TableCell align="left">Customer Name</TableCell>
                  <TableCell align="left">Phone & Email</TableCell>
                  <TableCell align="left">Address</TableCell>
                  <TableCell align="left">Purchase Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow
                  key={agreement.id}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell component="th" scope="row">
                    {agreement?.agreementId}
                  </TableCell>
                  <TableCell align="left">
                    {agreement?.jbwCustomerName}
                  </TableCell>
                  <TableCell align="left">
                    <Typography
                      variant="subtitl1"
                      component="span"
                      sx={{
                        wordWrap: "break-word",
                      }}
                      whiteSpace="normal"
                    >
                      {agreement?.jbwPhoneNumber}
                    </Typography>
                    <br />
                    <Typography
                      variant="subtitl1"
                      component="span"
                      sx={{
                        wordWrap: "break-word",
                      }}
                      whiteSpace="normal"
                    >
                      {agreement?.jbwEmail}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography
                      variant="subtitl1"
                      component="span"
                      sx={{
                        wordWrap: "break-word",
                      }}
                      whiteSpace="normal"
                    >
                      {agreement.jbwAddressLine1
                        ? `${agreement.jbwAddressLine1},`
                        : ""}
                      {agreement?.jbwAddressLine2
                        ? `${agreement?.jbwAddressLine2},`
                        : ""}
                      {agreement?.jbwCity ? `${agreement?.jbwCity},` : ""}
                      {agreement?.jbwState ? `${agreement?.jbwState},` : ""}
                      {agreement?.jbwZip ? `${agreement?.jbwZip}` : ""}.
                    </Typography>
                  </TableCell>

                  <TableCell align="left">
                    {agreement?.jbwPurchaseDate && (
                      <JBWDateLabel
                        inputDate={agreement?.jbwPurchaseDate}
                        dateFormat="MM/DD/YYYY"
                      />
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Typography
            variant="subtitle1"
            component="h2"
            sx={{ fontWeight: "bold" }}
          >
            No JBW Agreement Details :
          </Typography>
        )}
      </>
    );
  };

  return (
    <>
      <Dialog
        open={dialogOpen}
        disableEscapeKeyDown
        PaperProps={{
          component: "div",
        }}
        fullWidth={true}
        maxWidth={"lg"}
      >
        <DialogTitle>Map Location</DialogTitle>
        {savingData ? (
          <DialogContent>
            <DialogContentText>Saving Location ...</DialogContentText>
          </DialogContent>
        ) : (
          <>
            <DialogContent>
              <Stack spacing={2}>
                <Item>
                  <Stack>
                    <Item elevation={0}>
                      <Typography
                        variant="subtitle1"
                        component="h2"
                        sx={{ fontWeight: "bold" }}
                      >
                        JBW Agreement Details:
                      </Typography>
                    </Item>
                    <Item elevation={0}>{jbwAgreementsTable(agreement)}</Item>
                  </Stack>
                </Item>
                <Item elevation={0}>
                  <FormControl>
                    <RadioGroup
                      row
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={searchType}
                      onChange={handleSearchTypeChange}
                    >
                      <FormControlLabel
                        value="address"
                        control={<Radio />}
                        label="Location Address"
                      />
                      <FormControlLabel
                        value="locationid"
                        control={<Radio />}
                        label="Location ID"
                      />
                    </RadioGroup>
                  </FormControl>
                </Item>
                <Item elevation={0}>
                  <STLocationSearch
                    dealer={agreement?.dealerId}
                    type={searchType}
                    searchValue={searchValue}
                    onLocationSelected={handleLocationSelected}
                  />
                </Item>
              </Stack>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button
                type="submit"
                disabled={!searchValue}
                onClick={handleSaveLocation}
              >
                Save
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </>
  );
};

export default STLocationSearchPopup;
