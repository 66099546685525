import React from "react";
import moment from "moment";
import Typography from "@mui/material/Typography";

const JBWDateLabel = ({ inputDate, dateFormat }) => {
  return (
    <Typography variant="label" component="label">
      {moment(inputDate).format(dateFormat)}
    </Typography>
  );
};

export default JBWDateLabel;
