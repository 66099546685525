import React, { useState, useContext, useEffect } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { ThemeProvider } from "@mui/material/styles";
import IconTextField from "../components/IconTextField";
import { EmailOutlined } from "@mui/icons-material";
import loginbg from "../assets/login-image.png";
import { useNavigate } from "react-router-dom";
import appTheme from "../themes/theme";
import { AuthContext } from "../AuthContext";
import { checkDealerOnboard, verifyDealer } from "../services/dealerServices";

const SignIn = () => {
  const {
    user,
    signIn,
    group,
    setDealer,
    setIsOnboarded,
    setOnboarding,
    loginError,
  } = useContext(AuthContext);
  let navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [passwordErr, setPasswordErr] = useState("");
  const [loginErr, setLoginErr] = useState("");
  const [isFormSubmittes, setIsFormSubmitted] = useState(false);
  // const [navigationLink, setNavigationLink] = useState("");

  const sigupNavigation = () => {
    navigate("signup");
  };
  const forgotPwdNavigation = () => {
    navigate("forgotpassword");
  };

  const formInputChange = (formField, value) => {
    if (formField === "email") {
      setEmail(value);
    }
    if (formField === "password") {
      setPassword(value);
    }
  };

  const validation = () => {
    return new Promise((resolve, reject) => {
      if (email === "" && password === "") {
        setEmailErr("Email is Required");
        setPasswordErr("Password is required");
        resolve({
          email: "Email is Required",
          password: "Password is required",
        });
      } else if (email === "") {
        setEmailErr("Email is Required");
        resolve({ email: "Email is Required", password: "" });
      } else if (password === "") {
        setPasswordErr("Password is required");
        resolve({ email: "", password: "Password is required" });
      } else if (password.length < 6) {
        setPasswordErr("must be 6 character");
        resolve({ email: "", password: "must be 6 character" });
      } else {
        resolve({ email: "", password: "" });
      }
    });
  };

  const handleClick = (event) => {
    event.preventDefault();
    setIsFormSubmitted(true);
    setEmailErr("");
    setPasswordErr("");
    setLoginErr("");
    validation()
      .then(
        (res) => {
          if (res.email === "" && res.password === "") {
            try {
              signIn(email, password);
              // console.log("signin error :", loginError);
              // if (loginError) {
              //   setLoginErr(loginError);
              //   setIsFormSubmitted(false);
              // }
            } catch (err) {
              console.log(err);
              setLoginErr(err);
              setIsFormSubmitted(false);
            }
          } else {
            setIsFormSubmitted(false);
          }
        },
        (err) => {
          console.log(err);
          setLoginErr("Unable to Login right now");
          setIsFormSubmitted(false);
        }
      )
      .catch((err) => {
        console.log(err);
        setLoginErr("Internal Server error.Please try again after some time");
        setIsFormSubmitted(false);
      });
  };

  useEffect(() => {
    if (loginError) {
      setLoginErr(loginError);
      setIsFormSubmitted(false);
    }
  }, [loginError]);

  console.log("SignIn useEffect :", user);
  if (user) {
    if (group !== "jbw-dealer") {
      navigate("/home/overview");
      //setNavigationLink("/home/overview");
    } else {
      checkDealerOnboard(user.profile)
        .then(
          (res) => {
            let data = res.data;
            if (data && data.length > 0) {
              console.log("dealer data in signin check dealer: ", data);
              verifyDealer(data[0].dealerId)
                .then((re) => {
                  const dealerData = re.data;
                  if (dealerData && dealerData.length > 0) {
                    setDealer(dealerData[0]);
                    console.log(
                      "dealer data in signin verify dealer: ",
                      dealerData[0]
                    );
                    localStorage.setItem(
                      "dealer",
                      JSON.stringify(dealerData[0])
                    );
                    navigate(`/home/dealerdetails`);
                  }

                  //setNavigationLink("/home/dealerdetails");
                })
                .catch((err) => {
                  console.error(err);
                  //navigate("/home/dealerdetails/");
                  //setNavigationLink("/home/dealerdetails");
                });
            } else {
              verifyDealer(user.profile)
                .then((re) => {
                  const dealerData = re.data;
                  if (dealerData && dealerData.length > 0) {
                    setDealer(dealerData[0]);
                    localStorage.setItem(
                      "dealer",
                      JSON.stringify(dealerData[0])
                    );
                    setOnboarding({
                      dealerId: dealerData[0].id,
                      dealerName: dealerData[0].dealerName,
                    });

                    //setNavigationLink("/home/onboarding/clientlocation");
                    setIsOnboarded(false);
                    navigate("/home/onboarding/clientlocation", {
                      state: { key: "onboardinghome" },
                    });
                  }
                })
                .catch((err) => {
                  console.error(err);
                  setLoginErr("Internal server error");
                });
            }
          },
          (err) => {
            console.log("error", err);
            //navigate("/home");
            //setNavigationLink("/home");
            setLoginErr("Unable get Onboarding Information ");
          }
        )
        .catch((err) => {
          console.log("Error check dealer onboard", err);
          // navigate("/home");
          //setNavigationLink("/home");
          setLoginErr("Internal Server Error");
        });
    }
  }

  // useEffect(() => {
  //   navigate(navigationLink);
  // }, [navigationLink]);

  return (
    <ThemeProvider theme={appTheme}>
      <Grid container sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `url(${loginbg})`,
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            m="auto"
            sx={{
              width: 300,
              my: 8,
              mx: 4,
              ml: 20,
              mt: 20,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography component="h1" variant="h4">
              Hello Again!
            </Typography>
            <Typography component="h1" variant="h6">
              Welcome Back
            </Typography>
            <Box component="form" noValidate sx={{ mt: 1 }}>
              <IconTextField
                iconStart={
                  <EmailOutlined sx={{ color: "#bdbdbd", fontSize: 20 }} />
                }
                margin="normal"
                required
                fullWidth
                id="email"
                placeholder="Email Address"
                name="email"
                autoFocus
                value={email}
                onChange={(e) => formInputChange("email", e.target.value)}
                error={emailErr ? true : false}
                helperText={emailErr}
                size="small"
                disabled={isFormSubmittes}
              />
              <IconTextField
                iconStart={
                  <LockOutlinedIcon sx={{ color: "#bdbdbd", fontSize: 20 }} />
                }
                margin="normal"
                required
                fullWidth
                name="password"
                placeholder="Password"
                type="password"
                id="password"
                value={password}
                onChange={(e) => {
                  formInputChange("password", e.target.value);
                }}
                error={passwordErr ? true : false}
                helperText={passwordErr}
                size="small"
                disabled={isFormSubmittes}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  mt: 3,
                  mb: 2,
                  borderRadius: "50px",
                }}
                onClick={handleClick}
                size="small"
                disabled={isFormSubmittes}
              >
                Login
              </Button>
              <Grid container>
                <Grid item xs>
                  <Button
                    variant="text"
                    onClick={forgotPwdNavigation}
                    color="primary"
                    size="small"
                  >
                    Forgot password?
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="text"
                    color="primary"
                    onClick={sigupNavigation}
                    size="small"
                    disabled={isFormSubmittes}
                  >
                    SignUp
                  </Button>
                </Grid>
              </Grid>
              <Typography variant="body" sx={{ color: "#f44336" }}>
                {loginErr}
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default SignIn;
