import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";

import React, { useState } from "react";
import { userPasswordReset } from "../services/signupService";

const ResetPasswordDialog = (props) => {
  const { onClose, open, registeredEmail } = props;
  console.log("Password change registered email :", registeredEmail);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [pwdResetErr, setPwdResetErr] = useState("");

  const handleResetPassword = () => {
    setIsFormSubmitted(true);
    setPwdResetErr("");
    userPasswordReset({ email: registeredEmail })
      .then(
        (resData) => {
          console.log(resData.data);
          const body = resData.data;
          const statusCode = resData.status;
          if (statusCode === 200) {
            setIsFormSubmitted(false);
            restForm();
            onClose("success");
          } else {
            console.log("message :", JSON.parse(body).message);
            setIsFormSubmitted(false);
            setPwdResetErr(JSON.parse(body).message);
          }
        },
        (err) => {
          console.log(JSON.stringify(err));
          setIsFormSubmitted(false);
          setPwdResetErr("Cannot verify email");
        }
      )
      .catch((err) => {
        console.log(err);
        setIsFormSubmitted(false);
        setPwdResetErr("Internal Server Error");
      });
  };

  const handleClose = (event, reason) => {
    if (reason === "escapeKeyDown" || reason === "backdropClick") {
      return;
    }
    onClose("closed");
    restForm();
  };

  const restForm = () => {
    setIsFormSubmitted(false);
  };

  return (
    <Dialog onClose={handleClose} open={open} fullWidth disableEscapeKeyDown>
      <DialogTitle>Alert</DialogTitle>
      <DialogContent>
        <Box component="form" noValidate>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="stretch"
          >
            <Grid item xs={12} sm={6} md={6}>
              <Typography
                component="div"
                variant="h6"
                sx={{ fontSize: "14px" }}
              >
                Do you want to reset the password?
              </Typography>
            </Grid>

            <Grid item xs={12} sm={6} md={6}>
              <Typography
                variant="body"
                sx={{ color: "#f44336", fontSize: "10px" }}
              >
                {pwdResetErr}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button disabled={isFormSubmitted ? true : false} onClick={handleClose}>
          Cancel
        </Button>
        <Button
          onClick={handleResetPassword}
          disabled={isFormSubmitted ? true : false}
        >
          Reset Password
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ResetPasswordDialog;
