import {
  Button,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import JBWDateLabel from "./JBWDateLabel";

const AgreementDetails = ({ agreementDetails, deletedEquipment }) => {
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "left",
    color: theme.palette.text.secondary,
  }));

  const [orgEquipments, setOrgEquioments] = useState();
  const [transEquipment, setTransEquipment] = useState();

  useEffect(() => {
    if (agreementDetails && agreementDetails.equipments) {
      setOrgEquioments(agreementDetails.equipments);
      setTransEquipment(agreementDetails.equipments);
    }
  }, [agreementDetails.id]);

  const agreementDetailTable = (agreement) => {
    return (
      <TableContainer>
        <Table
          sx={{ minWidth: 550 }}
          size="small"
          aria-label="JBW Agreements table"
        >
          <TableHead sx={{ fontSize: "10px" }}>
            <TableRow>
              <TableCell>Dealer Id</TableCell>
              <TableCell align="left">Tenant Id</TableCell>
              <TableCell align="left">Po Number</TableCell>
              <TableCell align="left">Job Number</TableCell>
              <TableCell align="left">Product Code</TableCell>
              <TableCell align="left">Customer Name</TableCell>
              <TableCell align="left">Phone & Email</TableCell>
              <TableCell align="left">Address</TableCell>
              <TableCell align="left">Purchase Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow
              key={agreement.id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
              }}
            >
              <TableCell component="th" scope="row">
                {agreement?.dealerId}
              </TableCell>
              <TableCell align="left">{agreement?.tenantId}</TableCell>
              <TableCell align="left">{agreement?.poNumber}</TableCell>
              <TableCell align="left">{agreement?.jobNumber}</TableCell>
              <TableCell align="left">{agreement?.productCode}</TableCell>
              <TableCell align="left">{agreement?.customerName}</TableCell>
              <TableCell align="left">
                <Typography
                  variant="subtitl1"
                  component="span"
                  sx={{
                    wordWrap: "break-word",
                  }}
                  whiteSpace="normal"
                >
                  {agreement?.phoneNumber}
                </Typography>
                <br />
                <Typography
                  variant="subtitl1"
                  component="span"
                  sx={{
                    wordWrap: "break-word",
                  }}
                  whiteSpace="normal"
                >
                  {agreement?.emailAddress}
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Typography
                  variant="subtitl1"
                  component="span"
                  sx={{
                    wordWrap: "break-word",
                  }}
                  whiteSpace="normal"
                >
                  {agreement.location}.
                </Typography>
              </TableCell>

              <TableCell align="left">
                {agreement?.startDate && (
                  <JBWDateLabel
                    inputDate={agreement?.startDate}
                    dateFormat="MM/DD/YYYY"
                  />
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const handleDelete = (e, params) => {
    e.preventDefault();
    console.log("deleting equipments :", params);
    setTransEquipment((oldValue) =>
      oldValue.filter((val) => val.id !== params.id)
    );
    deletedEquipment(params);
  };

  const equipmentsTable = (equipments) => {
    return (
      <TableContainer sx={{ maxHeight: 200 }}>
        <Table
          sx={{ minWidth: 550 }}
          size="small"
          aria-label="JBW Agreement Equipments table"
          stickyHeader
        >
          <TableHead sx={{ fontSize: "10px" }}>
            <TableRow>
              <TableCell align="left">Manufacturer</TableCell>
              <TableCell align="left">Model Number</TableCell>
              <TableCell align="left">Serial Number</TableCell>
              <TableCell align="center">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {equipments.map((equipment) => (
              <TableRow
                key={equipment.id}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                <TableCell align="left">{equipment?.manufacturer}</TableCell>
                <TableCell align="left">{equipment?.modelNo}</TableCell>
                <TableCell align="left">{equipment?.serialNumber}</TableCell>
                <TableCell align="center">
                  <IconButton
                    color="error"
                    aria-label="delete"
                    onClick={(e) => handleDelete(e, equipment)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <Stack spacing={2}>
      <Item>
        <Stack>
          <Item elevation={0}>
            <Typography
              variant="subtitle1"
              component="h2"
              sx={{ fontWeight: "bold" }}
            >
              Agreement Details:
            </Typography>
          </Item>
          <Item elevation={0}>{agreementDetailTable(agreementDetails)}</Item>
        </Stack>
      </Item>
      <Item>
        <Stack>
          <Item elevation={0}>
            <Typography
              variant="subtitle1"
              component="h2"
              sx={{ fontWeight: "bold" }}
            >
              Agreement Equipments:
            </Typography>
          </Item>
          {transEquipment ? (
            <Item elevation={0}>{equipmentsTable(transEquipment)}</Item>
          ) : (
            <Item elevation={0}>
              <Typography
                variant="body"
                sx={{ color: "red", fontSize: "10px" }}
              >
                No Equipments
              </Typography>
            </Item>
          )}
        </Stack>
      </Item>
    </Stack>
  );
};

export default AgreementDetails;
