import { Box, Button, Grid, Typography } from "@mui/material";

import React, { useContext, useEffect, useState } from "react";
import { useLoaderData, useLocation, useNavigate } from "react-router-dom";
import ClientProgressBar from "../../components/ClientProgressBar";
import IconTextField from "../../components/IconTextField";
import { AuthContext } from "../../AuthContext";

const ClientLocation = () => {
  let navigate = useNavigate();
  let urlLocation = useLocation();
  console.log("urlLocation :", urlLocation.state.key);
  let { dealer, isOnboarded, setOnboarding, onboarding, group } =
    useContext(AuthContext);
  console.log("clientLocation dealer:", dealer);
  const [tenantName, setTenantName] = useState("");
  const [addressLine, setAddressLine] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [city, setCity] = useState("");
  const [dealersState, setDealerState] = useState("");

  useEffect(() => {
    if (urlLocation.state?.key !== "tenantlist") {
      setTenantName(dealer?.dealerName);
    }

    setAddressLine(dealer?.addressline_1 ? dealer?.addressline_1 : "");
    setZipCode(dealer?.zipcode ? dealer?.zipcode : "");
    setCity(dealer?.city ? dealer?.city : "");
    setDealerState(dealer?.state ? dealer?.state : "");
  }, [dealer]);

  const clientNameHandler = () => {
    navigate("/home/onboarding/clientname", {
      state: { key: urlLocation.state?.key },
    });
  };
  const clientCustomHandler = () => {
    let location = {
      tenantName: tenantName,
      addressLine: addressLine,
      city: city,
      state: dealersState,
      zipcode: zipCode,
    };
    setOnboarding({ ...onboarding, location });
    navigate("/home/onboarding/clientcustome", {
      state: { key: urlLocation.state?.key },
    });
  };
  const cancelnHandler = () => {
    if (urlLocation.state?.key === "tenantlist") {
      navigate("/home/dealerdetails");
    } else if (urlLocation.state?.key === "onboardinghome") {
      navigate("/home/onboardinghome");
    } else if (isOnboarded && group !== "jbw-dealer") {
      navigate("/home/overview");
    }
  };

  const formInputChange = (formField, value) => {
    if (formField === "tenantName") {
      setTenantName(value);
    }
    if (formField === "addressLine") {
      setAddressLine(value);
    }
    if (formField === "zipCode") {
      setZipCode(value);
    }
    if (formField === "city") {
      setCity(value);
    }
    if (formField === "state") {
      setDealerState(value);
    }
  };

  return (
    <Box component="form" noValidate sx={{ mt: 1 }}>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        sx={{ minHeight: "80vh" }}
      >
        <Grid item xs={3}>
          <Typography variant="h5" component="h5">
            {`Enter the ${dealer?.dealerName} location?`}
          </Typography>
        </Grid>
        <Grid item xs={3} sx={{ mt: 2 }}>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Typography
              variant="body2"
              component="div"
              sx={{ color: "#a8a8a8d4" }}
            >
              Share the details of the new client you are onbaording
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Typography
              variant="body2"
              component="div"
              sx={{ color: "#a8a8a8d4" }}
            >
              Don't worry you can change these details later
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sx={{ flexGrow: 1, mt: 3 }}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
              width: 450,
              padding: 1,
            }}
          >
            <ClientProgressBar value={40} />
            <Typography sx={{ ml: 2 }}> 2/4</Typography>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
              width: 600,
              padding: 1,
            }}
          >
            <IconTextField
              variant="outlined"
              margin="normal"
              fullWidth
              value={tenantName}
              onChange={(e) => formInputChange("tenantName", e.target.value)}
              id="tenantName"
              label="Tenant Name"
              name="tenantName"
              autoFocus
              size="small"
            />
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
              width: 600,
              padding: 1,
            }}
          >
            <IconTextField
              variant="outlined"
              margin="normal"
              fullWidth
              value={addressLine}
              onChange={(e) => formInputChange("addressLine", e.target.value)}
              id="addressLine"
              label="Address Line"
              name="addressLine"
              size="small"
            />
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
              width: 600,
              padding: 1,
            }}
          >
            <IconTextField
              variant="outlined"
              margin="normal"
              fullWidth
              value={city}
              onChange={(e) => formInputChange("city", e.target.value)}
              id="city"
              label="City/Town"
              name="city"
              size="small"
            />
            <IconTextField
              variant="outlined"
              margin="normal"
              fullWidth
              value={dealersState}
              onChange={(e) => formInputChange("state", e.target.value)}
              id="state"
              label="State"
              name="state"
              size="small"
            />
            {/* <FormControl sx={{ mr: 1 }}>
              <InputLabel id="city-town">City/Town</InputLabel>
              <Select
                sx={{
                  width: 290,
                  height: 50,
                  borderRadius: 50,
                }}
                label="City/Town"
                labelId="city-town"
                size="small"
              >
                <MenuItem value={1}>Los Angeles</MenuItem>
                <MenuItem value={2}>New York</MenuItem>
                <MenuItem value={3}>San Francisco</MenuItem>
                <MenuItem value={4}>Chicago</MenuItem>
                <MenuItem value={5}>Houston</MenuItem>
                <MenuItem value={5}>San Diego</MenuItem>
                <MenuItem value={5}>Philadelphia</MenuItem>
                <MenuItem value={5}>Atlanta</MenuItem>
                <MenuItem value={5}>Austin</MenuItem>
              </Select>
            </FormControl>
            <FormControl sx={{ ml: 1 }}>
              <InputLabel id="country">Country</InputLabel>
              <Select
                sx={{
                  width: 290,
                  height: 50,
                  borderRadius: 50,
                }}
                label="Country"
                labelId="country"
                size="small"
              >
                <MenuItem value={1}>USA</MenuItem>
              </Select>
            </FormControl> */}
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
              width: 600,
              padding: 1,
            }}
          >
            <IconTextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="zipCode"
              label="Zip Code"
              name="zipCode"
              value={zipCode}
              onChange={(e) => formInputChange("zipCode", e.target.value)}
              size="small"
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box
            sx={{
              width: 600,
              padding: 1,
              display: "flex",
              flexDirection: "row",
            }}
          >
            {isOnboarded && group !== "jbw-dealer" && (
              <Button
                variant="text"
                color="error"
                size="small"
                onClick={cancelnHandler}
              >
                Cancel
              </Button>
            )}
            <Box sx={{ flexGrow: 1 }} />
            {isOnboarded &&
              group !== "jbw-dealer" &&
              urlLocation.state?.key !== "tenantlist" && (
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  sx={{
                    borderRadius: "50px",
                    mr: 2,
                  }}
                  onClick={clientNameHandler}
                >
                  Client Name
                </Button>
              )}

            <Button
              variant="contained"
              color="secondary"
              size="small"
              sx={{
                borderRadius: "50px",
              }}
              onClick={clientCustomHandler}
            >
              Custom Fields
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ClientLocation;
