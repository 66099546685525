import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { ThemeProvider } from "@mui/material/styles";
import IconTextField from "../components/IconTextField";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import loginbg from "../assets/login-image.png";
import { useNavigate, useParams } from "react-router-dom";
import appTheme from "../themes/theme";
import { List, ListItemText, Stack } from "@mui/material";
import { userPasswordChange } from "../services/signupService";
import { decryptData } from "../utils/encryption";

const ChangePassword = () => {
  const { id } = useParams();
  let navigate = useNavigate();
  const [registeredEmail, setRegisteredEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordErr, setPasswordErr] = useState("");
  const [confirmPasswordErr, setConfirmPasswordErr] = useState("");
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [pwdChangeErr, setPwdChangeErr] = useState("");

  useEffect(() => {
    if (id) {
      const decoded = decodeURIComponent(id);
      const dyData = decryptData(decoded);
      console.log("dycrepeted Data :", dyData);
      if (dyData && dyData.email) {
        setRegisteredEmail(dyData.email);
      }
    }
  }, [id]);

  const homeNavigation = () => {
    restForm();
    navigate("../");
  };

  const formInputChange = (formField, value) => {
    if (formField === "newPassword") {
      setPassword(value);
    }
    if (formField === "confirmPassword") {
      setConfirmPassword(value);
    }
  };

  const validation = () => {
    return new Promise((resolve, reject) => {
      if (password === "" && confirmPassword === "") {
        setPasswordErr("New Password required");
        setConfirmPasswordErr("Confirm Password required");
        resolve({
          password: "New Password required",
          confirmPassword: "Confirm Password required",
        });
      } else if (password === "") {
        setPasswordErr("New Password required");
        resolve({
          password: "New Password required",
          confirmPassword: "",
        });
      } else if (!validatePassword(password)) {
        setPasswordErr("Invalid Password");
        resolve({
          password: "Invalid Password",
          confirmPassword: "",
        });
      } else if (confirmPassword === "") {
        setConfirmPasswordErr("Confirm Password required");
        resolve({
          password: "",
          confirmPassword: "Confirm Password required",
        });
      } else if (!validatePassword(confirmPassword)) {
        setConfirmPasswordErr("Invalid Confirm Password");
        resolve({
          password: "",
          confirmPassword: "Invalid Confirm Password",
        });
      } else if (confirmPassword !== password) {
        setConfirmPasswordErr("Confirm Password and New Password must be same");
        resolve({
          password: "",
          confirmPassword: "Confirm Password and New Password must be same",
        });
      } else {
        resolve({ password: "", confirmPassword: "" });
      }
      reject("");
    });
  };

  const validatePassword = (password) => {
    const regex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return regex.test(password);
  };

  const handleChangePassword = () => {
    setIsFormSubmitted(true);
    setPwdChangeErr("");
    setPasswordErr("");
    setConfirmPassword("");
    validation()
      .then(
        (res) => {
          if (res.password === "" && res.confirmPassword === "") {
            const passwordData = {
              email: registeredEmail,
              password: password,
            };
            userPasswordChange(passwordData)
              .then(
                (res) => {
                  console.log("password change response :", res.status);
                  console.log("password change response :", res.data);
                  if (res.status === 200) {
                    restForm();
                    homeNavigation();
                  } else {
                    setPwdChangeErr(res.data);
                    setIsFormSubmitted(false);
                  }
                },
                (err) => {
                  console.log("Passwoed change service issue :", err);
                  setPwdChangeErr("Error calling Password Change servive");
                  setIsFormSubmitted(false);
                }
              )
              .catch((err) => {
                console.error("Error", err);
                setPwdChangeErr("Internal Server error");
                setIsFormSubmitted(false);
              });
          } else {
            setIsFormSubmitted(false);
          }
        },
        (err) => {
          console.log("Error validating the password :", err);
          setIsFormSubmitted(false);
        }
      )
      .catch((err) => {
        console.log(err);
        setIsFormSubmitted(false);
      });
  };

  const restForm = () => {
    setPasswordErr("");
    setConfirmPasswordErr("");
    setPassword("");
    setConfirmPassword("");
    setIsFormSubmitted(false);
  };

  return (
    <ThemeProvider theme={appTheme}>
      <Grid container sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `url(${loginbg})`,
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            m="auto"
            sx={{
              width: 300,
              my: 8,
              mx: 4,
              ml: 20,
              mt: 20,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography component="h1" variant="h4">
              Hello Again!
            </Typography>
            <Typography component="h1" variant="h6">
              Please Change Your Password
            </Typography>
            <Box component="form" noValidate>
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="stretch"
              >
                <Grid item xs={12} sm={6} md={6}>
                  <IconTextField
                    iconStart={
                      <LockOutlinedIcon
                        sx={{ color: "#bdbdbd", fontSize: 20 }}
                      />
                    }
                    margin="normal"
                    id="newPassword"
                    label="New Password"
                    type="password"
                    fullWidth
                    value={password}
                    onChange={(e) =>
                      formInputChange("newPassword", e.target.value)
                    }
                    error={passwordErr ? true : false}
                    helperText={passwordErr}
                    size="small"
                    disabled={isFormSubmitted ? true : false}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <IconTextField
                    iconStart={
                      <LockOutlinedIcon
                        sx={{ color: "#bdbdbd", fontSize: 20 }}
                      />
                    }
                    margin="normal"
                    id="confirmPassword"
                    label="Confirm Password"
                    type="password"
                    fullWidth
                    value={confirmPassword}
                    onChange={(e) =>
                      formInputChange("confirmPassword", e.target.value)
                    }
                    error={confirmPasswordErr ? true : false}
                    helperText={confirmPasswordErr}
                    size="small"
                    disabled={isFormSubmitted ? true : false}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Typography
                    variant="body"
                    sx={{ color: "#f44336", fontSize: "10px" }}
                  >
                    {pwdChangeErr}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={6} md={6}>
                  <Stack spacing={4}>
                    <Button
                      fullWidth
                      variant="contained"
                      sx={{
                        mt: 3,
                        mb: 2,
                        borderRadius: "50px",
                      }}
                      size="small"
                      onClick={handleChangePassword}
                      disabled={isFormSubmitted ? true : false}
                    >
                      Change Password
                    </Button>
                    <Button
                      variant="text"
                      color="primary"
                      onClick={homeNavigation}
                      size="small"
                      startIcon={<ArrowBackIcon />}
                    >
                      Back to Login Page
                    </Button>
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  {/* <Typography
                    variant="body"
                    sx={{ color: "#FFB200", fontSize: "10px" }}
                  >
                    Password policy : <br />
                    Password minimum length: 8 character(s)
                    <br />
                    Contains at least 1 number <br />
                    Contains at least 1 special character <br />
                    Contains at least 1 uppercase letter <br />
                    Contains at least 1 lowercase letter
                  </Typography> */}

                  <List>
                    <listItem>
                      <ListItemText
                        primary={
                          <Typography
                            variant="body"
                            sx={{
                              color: "#FFB200",
                              fontSize: "13px",
                              fontWeight: "bold",
                            }}
                          >
                            Password policy :
                          </Typography>
                        }
                      />
                    </listItem>
                    <listItem>
                      <ListItemText
                        sx={{ mt: 0, mb: 0 }}
                        primary={
                          <Typography
                            variant="body"
                            sx={{ color: "#FFB200", fontSize: "12px" }}
                          >
                            Password minimum length: 8 character(s)
                          </Typography>
                        }
                      />
                    </listItem>
                    <listItem>
                      <ListItemText
                        sx={{ mt: 0, mb: 0 }}
                        primary={
                          <Typography
                            variant="body"
                            sx={{ color: "#FFB200", fontSize: "12px" }}
                          >
                            Contains at least 1 number
                          </Typography>
                        }
                      />
                    </listItem>
                    <listItem>
                      <ListItemText
                        sx={{ mt: 0, mb: 0 }}
                        primary={
                          <Typography
                            variant="body"
                            sx={{ color: "#FFB200", fontSize: "12px" }}
                          >
                            Contains at least 1 special character
                          </Typography>
                        }
                      />
                    </listItem>
                    <listItem>
                      <ListItemText
                        sx={{ mt: 0, mb: 0 }}
                        primary={
                          <Typography
                            variant="body"
                            sx={{ color: "#FFB200", fontSize: "12px" }}
                          >
                            Contains at least 1 uppercase letter
                          </Typography>
                        }
                      />
                    </listItem>
                    <listItem>
                      <ListItemText
                        sx={{ mt: 0, mb: 0 }}
                        primary={
                          <Typography
                            variant="body"
                            sx={{ color: "#FFB200", fontSize: "12px" }}
                          >
                            Contains at least 1 lowercase letter
                          </Typography>
                        }
                      />
                    </listItem>
                  </List>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default ChangePassword;
