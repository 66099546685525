import { Chip } from "@mui/material";
import React from "react";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import JBWDateLabel from "./JBWDateLabel";
import FactoryIcon from "@mui/icons-material/Factory";
import CustomNoRowsOverlay from "./gridNoRowOverLay";

const TenantGrid = ({ tenantList, editTenant, onSelectedTenant }) => {
  const columns = [
    {
      field: "image",
      headerName: "",
      width: 80,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (param) => {
        return <FactoryIcon />;
      },
    },
    {
      field: "tenantId",
      headerName: "Tenant ID",
      width: 250,
      sortable: false,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "tenantName",
      headerName: "Tenant Name",
      width: 250,
      sortable: false,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "createdOn",
      headerName: "Create Date",
      width: 150,
      sortable: false,
      renderCell: (param) => {
        return (
          <JBWDateLabel inputDate={param.value} dateFormat="MMM.DD,YYYY" />
        );
      },
      headerAlign: "center",
      align: "center",
    },
    {
      field: "status",
      headerName: "Status",
      width: 250,
      headerAlign: "center",
      align: "center",
      renderCell: (param) => {
        const bgColor = param.value === "InActive" ? "#DEB729" : "#4ECC72";
        return (
          <Chip
            sx={{
              color: "#fff",
              backgroundColor: bgColor,
              mt: 1,
              mb: 1,
              width: "80%",
            }}
            label={param.value}
          />
        );
      },
      sortable: false,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Action",
      cellClassName: "actions",
      width: 250,
      headerAlign: "center",
      align: "center",
      getActions: (param) => {
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEdit(param)}
            color="primary"
            title="Edit User"
          />,
          // <GridActionsCellItem
          //   icon={<VisibilityIcon />}
          //   label="View Tenant"
          //   onClick={handleView(param)}
          //   color="secondary"
          //   title="Change Password"
          // />,
        ];
      },
      sortable: false,
    },
  ];

  const handleEdit = (param) => () => {
    console.log("Edit clicked ", param.row);
    editTenant(param.row);
  };

  const handleView = (param) => () => {
    console.log("Edit clicked ", param.row);
    //editUser(param.row);
  };

  const TenantSelected = (param) => {
    console.log("selected tenant id", param.row);
    //setRestGridRows(false);
    //apiRef.current.selectRow(param.tenantId, true, false);
    onSelectedTenant(param.row);
  };

  return (
    <DataGrid
      rows={tenantList}
      getRowId={(row) => row.tenantId}
      columns={columns}
      onRowClick={(param) => {
        TenantSelected(param);
      }}
      autoHeight
      disableRowSelectionOnClick={tenantList && tenantList.length < 2}
      getRowHeight={() => "auto"}
      slots={{ noRowsOverlay: CustomNoRowsOverlay }}
      slotProps={{
        loadingOverlay: {
          variant: "skeleton",
          noRowsVariant: "skeleton",
        },
      }}
      pageSizeOptions={[3]}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: 3,
          },
        },
      }}
      disableColumnFilter={true}
      disableColumnMenu={true}
      sx={{
        "& .muidatagrid-colcelltitle ": {
          display: "block",
          textAlign: "center",
          width: "100%",
        },
      }}
    />
  );
};

export default TenantGrid;
