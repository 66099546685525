import { Autocomplete, Box, Stack, TextField } from "@mui/material";
import { throttle } from "lodash";
import React, { useState, useEffect } from "react";
import { getTblProductsSearch } from "../services/TblProductsServices";

const TblProductsSearch = ({ searchValue, onProductSelected }) => {
  //console.log("search value:", searchValue);
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);
  useEffect(() => {
    setValue({ productCode: searchValue });
  }, [searchValue]);
  //console.log("value :", value);
  const fetch = React.useMemo(
    () =>
      throttle((request) => {
        if (request?.input.length > 2) {
          const getData = getTblProductsSearch(request?.input);
          getData.then((res) => {
            console.log("products data memo :", res.data);
            setOptions(res.data);
            return res.data;
          });
        }
      }, 200),
    []
  );

  const onValueSelect = (data) => {
    console.log("selected Data", data);
    onProductSelected(data);
  };

  React.useEffect(() => {
    let active = true;
    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = [];
        if (value) {
          newOptions = [value];
        }
        if (results) {
          newOptions = [...newOptions, ...results];
        }
        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  return (
    <Autocomplete
      id="tbl-products-search"
      freeSolo
      sx={{ width: 250, borderRadius: 50 }}
      getOptionLabel={(option) => {
        return `${option.productCode ? option.productCode : ""}`;
      }}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      loading={true}
      loadingText={"Loading Plan Id's..."}
      isOptionEqualToValue={(option, value) =>
        option.productCode === value.productCode
      }
      noOptionsText={"No Plan Id Found , Please enter Plan Id "}
      onChange={(event, newValue) => {
        console.log("newValue", newValue);
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue ? newValue : { productCode: searchValue });
        onValueSelect(newValue ? newValue : { productCode: searchValue });
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          sx={{ fontSize: 12 }}
          variant="standard"
          label="Plan Id Search"
        />
      )}
      renderOption={(props, option) => (
        <Box component="li" sx={{ fontSize: 12 }} {...props} key={option.id}>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={0.5}
          >
            <p>Plan Id : {option.productCode}</p>
          </Stack>
        </Box>
      )}
    />
  );
};

export default TblProductsSearch;
