import { Box, Container, Grid } from "@mui/material";
import React from "react";
import { Outlet } from "react-router-dom";

const OnboardingLayout = () => {
  return (
    <Box component="main">
      <Container maxWidth="xl">
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} lg={12}>
            <Box
              sx={{
                maxWidth: "100%",
                padding: 2,
                boxShadow: 4,
                borderRadius: 3,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Outlet />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default OnboardingLayout;
