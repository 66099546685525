import React, { useState } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

import Typography from "@mui/material/Typography";
import { ThemeProvider } from "@mui/material/styles";
import IconTextField from "../components/IconTextField";
import { AccountCircle, EmailOutlined } from "@mui/icons-material";
import BusinessIcon from "@mui/icons-material/Business";
import BadgeIcon from "@mui/icons-material/Badge";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import loginbg from "../assets/login-image.png";
import { useNavigate } from "react-router-dom";
import { userSignup } from "../services/signupService";
import { verifyDealer } from "../services/dealerServices";
import { verifyDealerRegistration } from "../services/dealerRegisterServices";
import appTheme from "../themes/theme";

const Register = () => {
  const Navigate = useNavigate();
  const [confirmUser, setConfirmUser] = useState(true);
  const [email, setEmail] = useState("");
  const [fullName, setFullName] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [fullNameErr, setFullNameErr] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyNameErr, setCompanyNameErr] = useState("");
  const [dealerId, setDealerId] = useState("");
  const [dealerIdErr, setDealerIdErr] = useState("");
  const [dealerErr, setDealerErr] = useState("");
  const [isFormSubmitted, setIsFormSubmitted] = useState(true);
  const [enableLogin, setEnableLogin] = useState(true);
  const [disableDealerId, setDisableDealerId] = useState(false);

  const formInputChange = (formField, value) => {
    if (formField === "email") {
      setEmail(value);
    }
    if (formField === "fullName") {
      setFullName(value);
    }
    if (formField === "companyName") {
      setCompanyName(value);
    }
    if (formField === "dealerId") {
      setDealerId(value);
    }
  };

  const dealerIdValidation = () => {
    return new Promise((resolve, reject) => {
      if (dealerId === "") {
        setDealerIdErr("Dealer ID required");
        resolve({ dealerId: "Dealer Id required" });
      } else {
        resolve({ dealerId: "" });
      }
      reject("");
    });
  };

  const validation = () => {
    return new Promise((resolve, reject) => {
      if (
        email === "" &&
        fullName === "" &&
        companyName === "" &&
        dealerId === ""
      ) {
        setEmailErr("Email is Required");
        setFullNameErr("Full Name is Required");
        setCompanyNameErr("Company Name is Required");
        setDealerIdErr("Dealer ID is Required");
        resolve({
          email: "Email is Required",
          fullName: "Full Name is Required",
          companyName: "Company Name is Required",
          dealerId: "Dealer ID is Required",
        });
      } else if (email === "") {
        setEmailErr("Email is Required");
        resolve({
          email: "Email is Required",
          fullName: "",
          companyName: "",
          dealerId: "",
        });
      } else if (fullName === "") {
        setFullNameErr("Full Name is required");
        resolve({
          email: "",
          fullName: "Full Name is required",
          companyName: "",
          dealerId: "",
        });
      } else if (dealerId === "") {
        setDealerIdErr("Dealer ID is required");
        resolve({
          email: "",
          fullName: "",
          companyName: "",
          dealerId: "Dealer ID is required",
        });
      } else if (companyName === "") {
        setCompanyNameErr("Company Name is required");
        setDealerErr("Please valid Dealer ID to Populate Company Name");
        resolve({
          email: "",
          fullName: "",
          companyName: "Company Name is required",
          dealerId: "",
        });
      } else {
        resolve({
          email: "",
          fullName: "",
          companyName: "",
          dealerId: "",
        });
      }
      reject("");
    });
  };

  const handleVerifyDealer = (dealerId) => {
    setCompanyName("");
    setEmailErr("");
    setFullNameErr("");
    setCompanyNameErr("");
    setDealerIdErr("");
    setDealerErr("");
    setEnableLogin(false);
    setDisableDealerId(true);
    dealerIdValidation()
      .then(
        (res) => {
          console.log(res);
          if (res.dealerId === "") {
            verifyDealerRegistration(dealerId)
              .then(
                (dr) => {
                  let da = dr.data;
                  if (da && da.length > 0) {
                    setDealerIdErr(
                      "Dealer already registrated.You can login using existing Credentials"
                    );
                    setDisableDealerId(false);
                    setEnableLogin(true);
                  } else {
                    verifyDealer(dealerId)
                      .then(
                        (res) => {
                          let data = res.data;
                          if (data && data.length > 0) {
                            // if (data[0].registered === 1) {
                            //   setDealerIdErr(
                            //     "Dealer already registrated.You can login using existing Credentials"
                            //   );

                            //   setDisableDealerId(false);
                            //   setEnableLogin(true);
                            // } else {
                            //   setCompanyName(data[0].dealerName);
                            //   setIsFormSubmitted(false);
                            //   setDisableDealerId(false);
                            //   setEnableLogin(true);
                            // }
                            setCompanyName(data[0].dealerName);
                            setIsFormSubmitted(false);
                            setDisableDealerId(false);
                            setEnableLogin(true);
                          } else {
                            setIsFormSubmitted(true);
                            setDisableDealerId(false);
                            setEnableLogin(true);
                            setDealerIdErr(
                              "No dealer Found with give Dealer ID, Please Login to JB Warrenties check the Dealer Id"
                            );
                          }
                        },
                        (err) => {
                          console.log(err);
                          setDealerErr("Error in verifying the dealer");
                          setDisableDealerId(false);
                          setEnableLogin(true);
                        }
                      )
                      .catch((err) => {
                        console.log(err);
                        setDealerErr("Ineternal error try again");
                        setDisableDealerId(false);
                        setEnableLogin(true);
                      });
                  }
                },
                (err) => {
                  console.log(err);
                  setDealerErr("Unable to verify dealer registration");
                  setDisableDealerId(false);
                  setEnableLogin(true);
                }
              )
              .catch((err) => {
                console.log(err);
                setEnableLogin(true);
                setDisableDealerId(false);
                setDealerErr("Ineternal error try again");
              });
          } else {
            setEnableLogin(true);
            setDisableDealerId(false);
          }
        },
        (err) => {
          console.log(err);
          setEnableLogin(true);
          setDisableDealerId(false);
        }
      )
      .catch((err) => {
        setEnableLogin(true);
        setDisableDealerId(false);
        console.log(err);
      });
  };

  const handleClick = (event) => {
    event.preventDefault();
    setEmailErr("");
    setFullNameErr("");
    setCompanyNameErr("");
    setDealerIdErr("");
    setDealerErr("");
    setIsFormSubmitted(true);
    setDisableDealerId(true);
    setEnableLogin(false);
    validation()
      .then(
        (res) => {
          if (
            res.email === "" &&
            res.fullName === "" &&
            res.companyName === "" &&
            res.dealerId === ""
          ) {
            const userData = {
              email: email,
              fullname: fullName,
              profile: dealerId,
              companyname: companyName,
              group: "jbw-dealer",
            };
            userSignup(userData)
              .then(
                (data) => {
                  console.log(data);
                  setConfirmUser(false);
                  setIsFormSubmitted(false);
                  setDisableDealerId(false);
                  setEnableLogin(true);
                },
                (err) => {
                  console.log(JSON.stringify(err));
                  setIsFormSubmitted(false);
                  setDisableDealerId(false);
                  setDealerErr("Couldn't sign up");
                  setEnableLogin(true);
                }
              )
              .catch((err) => {
                console.log(err);
                setIsFormSubmitted(false);
                setDisableDealerId(false);
                setEnableLogin(true);
              });
          } else {
            setIsFormSubmitted(false);
            setDisableDealerId(false);
            setEnableLogin(true);
          }
        },
        (err) => {
          console.log(err);
          setIsFormSubmitted(false);
          setDisableDealerId(false);
          setEnableLogin(true);
        }
      )
      .catch((err) => {
        console.log(err);
        setIsFormSubmitted(false);
        setDisableDealerId(false);
        setEnableLogin(true);
      });
  };

  const handleBackToLoging = () => {
    Navigate("/");
  };

  return (
    <ThemeProvider theme={appTheme}>
      <Grid container sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `url(${loginbg})`,
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />

        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          {confirmUser ? (
            <Box
              m="auto"
              sx={{
                width: 300,
                my: 8,
                mx: 4,
                ml: 20,
                mt: 15,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography component="h1" variant="h5">
                Hello!
              </Typography>
              <Typography component="h1" variant="h6">
                Sign Up to Get Started
              </Typography>
              <Box component="form" noValidate sx={{ mt: 1 }}>
                <IconTextField
                  iconStart={
                    <BadgeIcon sx={{ color: "#bdbdbd", fontSize: 20 }} />
                  }
                  margin="normal"
                  required
                  fullWidth
                  autoFocus
                  id="dealerId"
                  placeholder="Dealer ID"
                  name="dealerId"
                  autoComplete="dealerId"
                  value={dealerId}
                  onChange={(e) => formInputChange("dealerId", e.target.value)}
                  onBlur={(e) => handleVerifyDealer(e.target.value)}
                  error={dealerIdErr ? true : false}
                  helperText={dealerIdErr}
                  size="small"
                  disabled={disableDealerId ? true : false}
                />
                <IconTextField
                  iconStart={
                    <BusinessIcon sx={{ color: "#bdbdbd", fontSize: 20 }} />
                  }
                  margin="normal"
                  required
                  fullWidth
                  id="companyName"
                  placeholder="Company Name"
                  name="companyName"
                  autoComplete="companyName"
                  value={companyName}
                  onChange={(e) =>
                    formInputChange("companyName", e.target.value)
                  }
                  disabled={true}
                  error={companyNameErr ? true : false}
                  helperText={companyNameErr}
                  size="small"
                />
                <IconTextField
                  iconStart={
                    <AccountCircle sx={{ color: "#bdbdbd", fontSize: 20 }} />
                  }
                  margin="normal"
                  required
                  fullWidth
                  id="fullName"
                  placeholder="Full Name"
                  name="fullName"
                  autoComplete="fullName"
                  value={fullName}
                  onChange={(e) => formInputChange("fullName", e.target.value)}
                  error={fullNameErr ? true : false}
                  helperText={fullNameErr}
                  size="small"
                  disabled={isFormSubmitted ? true : false}
                />

                <IconTextField
                  iconStart={
                    <EmailOutlined sx={{ color: "#bdbdbd", fontSize: 20 }} />
                  }
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  placeholder="Email Address"
                  name="email"
                  autoComplete="email"
                  value={email}
                  onChange={(e) => formInputChange("email", e.target.value)}
                  error={emailErr ? true : false}
                  helperText={emailErr}
                  size="small"
                  disabled={isFormSubmitted ? true : false}
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{
                    mt: 3,
                    mb: 2,
                    borderRadius: "50px",
                  }}
                  onClick={handleClick}
                  size="small"
                  disabled={isFormSubmitted ? true : false}
                >
                  Register
                </Button>
                <Button
                  type="submit"
                  fullWidth
                  variant="text"
                  sx={{
                    borderRadius: "50px",
                  }}
                  onClick={handleBackToLoging}
                  startIcon={<ArrowBackIcon />}
                  size="small"
                  disabled={enableLogin ? false : true}
                >
                  Back to Login Page
                </Button>
                <Typography
                  variant="body"
                  sx={{ color: "#f44336", fontSize: "10px" }}
                >
                  {dealerErr}
                </Typography>
              </Box>
            </Box>
          ) : (
            <Box
              m="auto"
              sx={{
                width: 300,
                my: 8,
                mx: 4,
                ml: 20,
                mt: 20,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography component="h1" variant="h5">
                Hello!
              </Typography>
              <Typography component="h1" variant="h6">
                User Created Success fully please check your registered email
                for password update link
              </Typography>
              <Box component="form" noValidate sx={{ mt: 1 }}>
                <Button
                  type="submit"
                  fullWidth
                  variant="text"
                  sx={{
                    borderRadius: "50px",
                  }}
                  onClick={handleBackToLoging}
                  startIcon={<ArrowBackIcon />}
                  size="small"
                  disabled={enableLogin ? false : true}
                >
                  Back to Login Page
                </Button>
              </Box>
            </Box>
          )}
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default Register;
