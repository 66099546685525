import React from "react";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import { inputLabelClasses } from "@mui/material";

const IconTextField = ({ iconStart, iconEnd, InputProps, ...props }) => {
  return (
    <TextField
      InputLabelProps={{
        sx: {
          ml: 3.5,
          [`&.${inputLabelClasses.shrink}`]: {
            color: "orange",
          },
        },
        style: { color: "#bdbdbd" },
      }}
      sx={{
        "& .MuiOutlinedInput-root": {
          borderRadius: "50px",
          legend: {
            marginLeft: "30px",
          },
        },
      }}
      {...props}
      InputProps={{
        ...InputProps,
        startAdornment: iconStart ? (
          <InputAdornment position="start">{iconStart}</InputAdornment>
        ) : null,
        endAdornment: iconEnd ? (
          <InputAdornment position="end">{iconEnd}</InputAdornment>
        ) : null,
      }}
    />
  );
};

export default IconTextField;
