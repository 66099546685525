import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
  Box,
  Skeleton,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import JBWDateLabel from "./JBWDateLabel";
import { getJBWAgreementDetails } from "../services/JBWAgreementsService.js";

const JBWAgreementsDetailDialog = ({
  jbwAgreementId,
  dialogOpen = false,
  onClose,
}) => {
  const [agreement, setAgreement] = useState(null);

  const loadAgreement = (_jbwAgreementId) => {
    getJBWAgreementDetails(_jbwAgreementId)
      .then(
        (res) => {
          console.log("jbwagreement Response :", res);
          const data = res.data;
          console.log("jbwagreement :", data);
          const statusCode = res.status;
          if (statusCode === 200 && data) {
            setAgreement(data);
          }
        },
        (err) => {
          console.log("Unable to load data from jbwagreement service", err);
        }
      )
      .catch((err) => {
        console.error("Get jbwagreement Internal Server error", err);
      });
  };

  useEffect(() => {
    loadAgreement(jbwAgreementId);
  }, [jbwAgreementId]);

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "left",
    color: theme.palette.text.secondary,
  }));
  const handleClose = () => {
    setAgreement(null);
    onClose(false);
  };
  return (
    <>
      <Dialog
        open={dialogOpen}
        disableEscapeKeyDown
        PaperProps={{
          component: "div",
        }}
        fullWidth={true}
        maxWidth={"lg"}
      >
        <DialogTitle>Agreement Details</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          {agreement === null ? (
            <Box sx={{ height: "100px" }}>
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
            </Box>
          ) : (
            <TableContainer>
              <Table
                sx={{ minWidth: 550 }}
                size="small"
                aria-label="JBW Aggrements table"
              >
                <TableHead sx={{ fontSize: "10px" }}>
                  <TableRow>
                    <TableCell>Agreement Id</TableCell>
                    <TableCell align="left">Customer Name</TableCell>
                    <TableCell align="left">Phone & Email</TableCell>
                    <TableCell align="left">Address</TableCell>
                    <TableCell align="left">Purchase Date</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow
                    key={agreement.id}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {agreement?.jbwAgreementId}
                    </TableCell>
                    <TableCell align="left">
                      {agreement?.customerName}
                    </TableCell>
                    <TableCell align="left">
                      <Typography
                        variant="subtitl1"
                        component="span"
                        sx={{
                          wordWrap: "break-word",
                        }}
                        whiteSpace="normal"
                      >
                        {agreement?.jbwPhoneNumber}
                      </Typography>
                      <br />
                      <Typography
                        variant="subtitl1"
                        component="span"
                        sx={{
                          wordWrap: "break-word",
                        }}
                        whiteSpace="normal"
                      >
                        {agreement?.jbwEmail}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography
                        variant="subtitl1"
                        component="span"
                        sx={{
                          wordWrap: "break-word",
                        }}
                        whiteSpace="normal"
                      >
                        {agreement.location}
                      </Typography>
                    </TableCell>

                    <TableCell align="left">
                      {agreement?.purchaseDate && (
                        <JBWDateLabel
                          inputDate={agreement?.purchaseDate}
                          dateFormat="MM/DD/YYYY"
                        />
                      )}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default JBWAgreementsDetailDialog;
