import {
  ArrowCircleLeft,
  FormatListBulleted,
  PeopleAlt,
} from "@mui/icons-material";
import React, { useContext, useState } from "react";
import { NavLink } from "react-router-dom";
import jbwlogo from "../assets/jbw-logo.svg";
import { AuthContext } from "../AuthContext";
import DashboardIcon from "@mui/icons-material/Dashboard";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import AssignmentIcon from "@mui/icons-material/Assignment";

const Sidebar = (props) => {
  let { group } = useContext(AuthContext);
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
    props.navOpen(!isOpen);
  };

  const adminMenuItem = [
    {
      path: "overview",
      name: "Dashboard",
      icon: <DashboardIcon />,
    },
    {
      path: "onboardinghome",
      name: "Dealers",
      icon: <FormatListBulleted />,
    },
    {
      path: "userslist",
      name: "Users",
      icon: <PeopleAlt />,
    },
    {
      path: "todohome",
      name: "ToDo",
      icon: <FactCheckIcon />,
    },
    {
      path: "agreementssynchome",
      name: "AgreementsSync",
      icon: <AssignmentIcon />,
    },
  ];

  const dealerMenuItem = [
    {
      path: "dealerdetails",
      name: "Dashboard",
      icon: <DashboardIcon />,
    },
    {
      path: "todohome",
      name: "ToDo",
      icon: <FactCheckIcon />,
    },
    {
      path: "agreementssynchome",
      name: "AgreementsSync",
      icon: <AssignmentIcon />,
    },
  ];
  return (
    <div style={{ width: isOpen ? "200px" : "60px" }} className="sidebar">
      <div className="top_section">
        <div className="logo" style={{ display: isOpen ? "block" : "none" }}>
          <img src={jbwlogo} alt="JBW-LOGO" height={100} />
        </div>
        <div
          style={{
            marginLeft: isOpen ? "15px" : "0px",
          }}
          className="bars"
        >
          {isOpen ? (
            <ArrowCircleLeft onClick={toggle} />
          ) : (
            <div className="logo" onClick={toggle}>
              <img
                src={jbwlogo}
                alt="JBW-LOGO"
                style={{
                  width: "40px",
                  height: "40px",
                }}
              />
            </div>
          )}
        </div>
      </div>
      {group === "jbw-dealer"
        ? dealerMenuItem.map((item, index) => (
            <NavLink
              to={item.path}
              key={index}
              className="link"
              activeclassname="active"
            >
              <div className="icon">{item.icon}</div>
              <div
                style={{ display: isOpen ? "block" : "none", fontSize: 14 }}
                className="link_text"
              >
                {item.name}
              </div>
            </NavLink>
          ))
        : adminMenuItem.map((item, index) => (
            <NavLink
              to={item.path}
              key={index}
              className="link"
              activeclassname="active"
            >
              <div className="icon">{item.icon}</div>
              <div
                style={{ display: isOpen ? "block" : "none", fontSize: 14 }}
                className="link_text"
              >
                {item.name}
              </div>
            </NavLink>
          ))}
    </div>
  );
};

export default Sidebar;
