import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
  Box,
  Skeleton,
  Stack,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import JBWDateLabel from "./JBWDateLabel";
import { getInvoiceDetailsByInvoiceId } from "../services/InvoiceService.js";

const InvoiceDetailsDialog = ({ invoiceId, dialogOpen = false, onClose }) => {
  const [invoice, setInvoice] = useState(null);
  const loadInvoice = (_invoiceId) => {
    getInvoiceDetailsByInvoiceId(_invoiceId)
      .then(
        (res) => {
          console.log("Invoice Response :", res);
          const data = res.data;
          console.log("Invoice :", data);
          const statusCode = res.status;
          if (statusCode === 200 && data && data.length > 0) {
            setInvoice(data[0]);
          }
        },
        (err) => {
          console.log("Unable to load data from Invoice service", err);
        }
      )
      .catch((err) => {
        console.error("Get Invoice Internal Server error", err);
      });
  };

  useEffect(() => {
    if (invoiceId) {
      loadInvoice(invoiceId);
    }
  }, [invoiceId]);

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "left",
    color: theme.palette.text.secondary,
  }));
  const handleClose = (event, reason) => {
    if (reason === "escapeKeyDown" || reason === "backdropClick") {
      return;
    }
    setInvoice(null);
    onClose(false);
  };
  return (
    <>
      <Dialog
        open={dialogOpen}
        disableEscapeKeyDown
        PaperProps={{
          component: "div",
        }}
        fullWidth={true}
        maxWidth={"lg"}
      >
        <DialogTitle>Invoice Details</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          {invoice === null ? (
            <Box sx={{ height: "100px" }}>
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
            </Box>
          ) : (
            <Stack spacing={2}>
              <Item>
                <TableContainer>
                  <Table
                    sx={{ minWidth: 550 }}
                    size="small"
                    aria-label="Invoice table"
                  >
                    <TableHead sx={{ fontSize: "10px" }}>
                      <TableRow>
                        <TableCell>Invoice Id</TableCell>
                        <TableCell align="left">Customer Name</TableCell>
                        <TableCell align="left">Phone & Email</TableCell>
                        <TableCell align="left">Address</TableCell>
                        <TableCell align="left">Invoice Date</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow
                        key={invoice.id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {invoice?.invoiceId}
                        </TableCell>
                        <TableCell align="left">
                          {invoice?.customerName}
                        </TableCell>
                        <TableCell align="left">
                          <Typography
                            variant="subtitl1"
                            component="span"
                            sx={{
                              wordWrap: "break-word",
                            }}
                            whiteSpace="normal"
                          >
                            {invoice?.phoneNumber}
                          </Typography>
                          <br />
                          <Typography
                            variant="subtitl1"
                            component="span"
                            sx={{
                              wordWrap: "break-word",
                            }}
                            whiteSpace="normal"
                          >
                            {invoice?.emailAddress}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Typography
                            variant="subtitl1"
                            component="span"
                            sx={{
                              wordWrap: "break-word",
                            }}
                            whiteSpace="normal"
                          >
                            {invoice.location}
                          </Typography>
                        </TableCell>

                        <TableCell align="left">
                          {invoice?.serviceStartDate && (
                            <JBWDateLabel
                              inputDate={invoice?.serviceStartDate}
                              dateFormat="MM/DD/YYYY"
                            />
                          )}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Item>
              <Item>
                <Stack>
                  <Item elevation={0}>
                    <Typography
                      variant="subtitle1"
                      component="h2"
                      sx={{ fontWeight: "bold" }}
                    >
                      Invoice Items:
                    </Typography>
                  </Item>
                  <Item elevation={0}>
                    {invoice.invoiceItems && invoice.invoiceItems.length > 0 ? (
                      <TableContainer>
                        <Table
                          sx={{ minWidth: 550 }}
                          size="small"
                          aria-label="Invoice Items table"
                        >
                          <TableHead sx={{ fontSize: "10px" }}>
                            <TableRow>
                              <TableCell>Item SKU Name</TableCell>
                              <TableCell align="left">Total Cost</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {invoice.invoiceItems.map((item) => (
                              <TableRow
                                key={item.id}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell component="th" scope="row">
                                  {item?.itemSKUName}
                                </TableCell>
                                <TableCell align="left">
                                  {item?.totalCost}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    ) : (
                      <Typography
                        variant="subtitle1"
                        component="h6"
                        sx={{ fontWeight: "bold" }}
                      >
                        NO Invoice Items
                      </Typography>
                    )}
                  </Item>
                </Stack>
              </Item>
            </Stack>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default InvoiceDetailsDialog;
