import { Chip } from "@mui/material";
import React, { useState } from "react";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import JBWDateLabel from "./JBWDateLabel";
import CustomNoRowsOverlay from "./gridNoRowOverLay";
import SummarizeIcon from "@mui/icons-material/Summarize";

const TransmissionGrid = ({
  transmissionListData,
  showDealer = false,
  onlogclicked,
}) => {
  const columns = [
    {
      field: "batchId",
      headerName: "Transmission ID",
      width: 130,
      sortable: false,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "tenantId",
      headerName: "Tenant Id",
      width: 100,
      sortable: false,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "syncStartDate",
      headerName: "Last Sync  Date",
      width: 150,
      sortable: false,
      renderCell: (param) => {
        return (
          <JBWDateLabel inputDate={param.value} dateFormat="MMM.DD,YYYY" />
        );
      },
      headerAlign: "center",
      align: "center",
    },
    {
      field: "destinationSystem",
      headerName: "DestinationSystem",
      width: 165,
      sortable: false,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "syncStatus",
      headerName: "Sync Status",
      width: 150,
      headerAlign: "center",
      align: "center",
      renderCell: (param) => {
        const bgColor =
          param.value === "Success"
            ? "#4caf50"
            : param.value === "In Progress"
            ? "#536dfe"
            : "#ff9100";
        return (
          <Chip
            sx={{
              color: "#fff",
              backgroundColor: bgColor,
              mt: 1,
              mb: 1,
              width: "80%",
            }}
            label={param.value}
          />
        );
      },
      sortable: false,
    },
  ];

  const handleRowClick = (params, event, details) => {
    console.log("Log clicked ", params.row);
    onlogclicked(params.row);
  };

  if (showDealer) {
    columns.splice(0, 0, {
      field: "dealerId",
      headerName: "Dealer ID",
      width: 100,
      sortable: false,
      headerAlign: "center",
      align: "center",
    });
  }

  return (
    <DataGrid
      rows={transmissionListData}
      getRowId={(row) => row.id}
      columns={columns}
      onRowClick={handleRowClick}
      slots={{ noRowsOverlay: CustomNoRowsOverlay }}
      getRowHeight={() => "auto"}
      pageSizeOptions={[5]}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: 5,
          },
        },
      }}
      disableColumnFilter={true}
      disableColumnMenu={true}
      sx={{
        "& .muidatagrid-colcelltitle ": {
          display: "block",
          textAlign: "center",
          width: "100%",
        },
        maxHeight: "50vh",
        height: "50vh",
      }}
    />
  );
};

export default TransmissionGrid;
