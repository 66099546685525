import { Box, Container, Grid } from "@mui/material";
import React, { useContext, useEffect } from "react";
import UsersCard from "../sections/users/userscard";
import { AuthContext } from "../AuthContext";

const UsersList = () => {
  const { setDealer } = useContext(AuthContext);
  useEffect(() => {
    setDealer(null);
    localStorage.removeItem("dealer");
  }, []);
  return (
    <Box component="main">
      <Container maxWidth="xl">
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} lg={12}>
            <UsersCard />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default UsersList;
