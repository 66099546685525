import axios from "./configs/axiosConfig";

export const getEmailNotificationList = async (dealerId) => {
  const url = `emailnotifications?dealerid=${dealerId}`;
  return await axios.get(url);
};

export const updateEmailNotification = async (id, viewed) => {
  const url = `emailnotifications/id=${id}?emailviewed=${viewed}`;
  return await axios.patch(url);
};
