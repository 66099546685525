import axios from "./configs/axiosConfig";

export const createDealerRegister = async (dealerregister) => {
  const url = `dealerregister/add`;
  return await axios.post(url, dealerregister);
};

export const verifyDealerRegistration = async (id) => {
  const url = `dealerregister/${id}/dealerverify`;
  return await axios.get(url);
};

export const getDealerRegistrationList = async () => {
  const url = `dealerregister`;
  return await axios.get(url);
};

export const verifyUserRegistered = async (email) => {
  const url = `dealerregister/userverify?email=${email}`;
  return await axios.get(url);
};

export const disableUser = async (data) => {
  const url = `dealerregister/userstatus`;
  return await axios.put(url, data);
};
