import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import IconTextField from "./IconTextField";
import { getTenantById, updateTenantById } from "../services/tenantServices";
import { verifyClient } from "../services/JWBServiceTitenService";
import JbwSwitch from "./JbwSwitch";

const TenantsDialog = (props) => {
  const { onClose, open, tenantData } = props;
  const [tenantId, setTenantId] = useState("");
  const [tenantName, setTenantName] = useState("");
  const [clientId, setClientId] = useState("");
  const [secretId, setSecretId] = useState("");
  const [tenantIdErr, setTenantIdErr] = useState("");
  const [tenantNameErr, setTenantNameErr] = useState("");
  const [clientIdErr, setClientIdErr] = useState("");
  const [secretIdErr, setSecretIdErr] = useState("");
  const [isValidTenant, setIsValidTenant] = useState(false);
  const [clientInfoErr, setClientInfoErr] = useState("");
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isActiveChecked, setIsActiveChecked] = useState(false);
  const [disableisActive, setDisableIsActive] = useState(false);
  const [showSTBtn, setShowSTBtn] = useState(false);

  const setInitData = () => {
    setTenantId(tenantData.tenantId);
    setTenantName(tenantData.tenantName);
    setClientId(tenantData.clientId);
    setSecretId(tenantData.clientSecret);
    setIsActiveChecked(tenantData.status === "Active" ? true : false);
    setTenantIdErr("");
    setIsValidTenant(true);
  };

  useEffect(() => {
    if (tenantData) {
      //setIsFormSubmitted(true);
      setInitData();
    }
  }, [tenantData]);

  const tenantIdValidation = () => {
    return new Promise((resolve, reject) => {
      if (tenantId === "") {
        setTenantIdErr("Tenant ID required");
        resolve({ tenantId: "Tenant ID required" });
      } else {
        resolve({ tenantId: "" });
      }
      reject("");
    });
  };

  const validation = () => {
    return new Promise((resolve, reject) => {
      if (
        clientId === "" &&
        secretId === "" &&
        tenantId === "" &&
        tenantName === ""
      ) {
        setClientIdErr("Client ID is Required");
        setSecretIdErr("Secret ID is required");
        setTenantIdErr("Tenant ID is Required");
        setTenantNameErr("Tenant Name is Required");
        resolve({
          clientId: "Client ID is Required",
          secretId: "Secret ID is required",
          tenantId: "Tenant ID is Required",
          tenantName: "Tenant Name is Required",
        });
      } else if (clientId === "") {
        setClientIdErr("Client ID is Required");
        resolve({
          clientId: "Client ID is Required",
          secretId: "",
          tenantId: "",
          tenantName: "",
        });
      } else if (secretId === "") {
        setSecretIdErr("Secret ID is required");
        resolve({
          clientId: "",
          secretId: "Secret ID is Required",
          tenantId: "",
          tenantName: "",
        });
      } else if (tenantId === "") {
        setTenantIdErr("Tenant ID is required");
        resolve({
          clientId: "",
          secretId: "",
          tenantId: "Tenant ID is Required",
          tenantName: "",
        });
      } else if (tenantName === "") {
        setTenantNameErr("Tenant Name is required");
        resolve({
          clientId: "",
          secretId: "",
          tenantId: "",
          tenantName: "Tenant Name Required",
        });
      } else {
        resolve({
          clientId: "",
          secretId: "",
          tenantId: "",
          tenantName: "",
        });
      }
      reject("");
    });
  };

  const formInputChange = (formField, value) => {
    if (formField === "tenantId") {
      setTenantId(value);
    }
    if (formField === "tenantName") {
      if (value !== tenantData.tenantName) {
        setDisableIsActive(true);
        setShowSTBtn(true);
      }
      setTenantName(value);
    }
    if (formField === "clientId") {
      if (value !== tenantData.clientId) {
        setDisableIsActive(true);
        setShowSTBtn(true);
      }
      setClientId(value);
    }
    if (formField === "secretId") {
      if (value !== tenantData.secretId) {
        setDisableIsActive(true);
        setShowSTBtn(true);
      }
      setSecretId(value);
    }
  };

  const clientConnectHandler = (event) => {
    if (event) {
      event.preventDefault();
      setClientInfoErr("");
    }

    setTenantIdErr("");
    setSecretIdErr("");
    setClientIdErr("");
    setTenantNameErr("");
    setIsValidTenant(false);

    validation()
      .then(
        (res) => {
          console.log("connect validation", res);
          if (
            res.tenantId === "" &&
            res.secretId === "" &&
            res.clientId === "" &&
            res.tenantName === ""
          ) {
            verifyClient(clientId, secretId)
              .then(
                (res) => {
                  const data = JSON.parse(res.data.body);
                  console.log("service titan data :", data);
                  if (data && !data.error) {
                    // let clientInfo = {
                    //   clientId,
                    //   clientSecret: secretId,
                    //   tenantId,
                    // };
                    // setOnboarding({ ...onboarding, clientInfo });
                    // navigate("/home/onboarding/clientconnect");
                    setDisableIsActive(false);
                    setIsValidTenant(true);
                    setShowSTBtn(false);
                    setClientInfoErr("");
                  } else {
                    setClientInfoErr("Invalid Client");
                    setIsValidTenant(true);
                  }
                },
                (err) => {
                  console.log("service title api error ", err);
                  setClientInfoErr(
                    "Unable to process request at Service Titan "
                  );
                  setIsValidTenant(true);
                }
              )
              .catch((err) => {
                console.log("service titan call failed :", err);
                setClientInfoErr("Unable to connect to Service Titan");
                setIsValidTenant(true);
              });
          }
        },
        (err) => {
          setClientId(err);
        }
      )
      .catch((err) => {
        setClientInfoErr(err);
      });
  };

  const handleTenantCheck = () => {
    console.log("verifying tenant", tenantId);

    setTenantIdErr("");
    setSecretIdErr("");
    setClientIdErr("");
    setTenantNameErr("");
    setIsValidTenant(false);
    setClientInfoErr("");
    tenantIdValidation()
      .then(
        (res) => {
          console.log(res);
          if (res.tenantId === "") {
            //service call to tenantid verification
            getTenantById(tenantId)
              .then(
                (res) => {
                  let data = res.data;
                  if (data && data.length > 0) {
                    setClientInfoErr("Tenant is already register");
                  } else {
                    setIsValidTenant(true);
                  }
                },
                (err) => {
                  setIsValidTenant(false);
                  console.log(err);
                  setClientInfoErr("Unable to verify tenant with the server");
                }
              )
              .catch((err) => {
                setIsValidTenant(false);
                console.error("tenant verifcation error", err);
                setClientInfoErr("Internal Server error");
              });
          } else {
            setIsValidTenant(false);
          }
        },
        (err) => {
          setIsValidTenant(false);
          setClientInfoErr(err);
        }
      )
      .catch((err) => {
        setIsValidTenant(false);
        setClientInfoErr(err);
      });
  };

  const handleClose = (event, reason) => {
    if (reason === "escapeKeyDown" || reason === "backdropClick") {
      return;
    }
    setInitData();
    setClientInfoErr("");
    onClose("close");
    //restForm();
  };

  const handleisActiveChecked = (e) => {
    const status = tenantData.status === "Active" ? true : false;
    setIsActiveChecked(e);
    if (e !== status) {
      if (e) {
        setClientInfoErr(
          "please wait while validating tenant with service titan.."
        );
        clientConnectHandler();
      }
    }
    console.log("active status changed ->", e);
  };

  const handleUpdate = (event) => {
    event.preventDefault();
    setIsValidTenant(false);
    setIsFormSubmitted(true);
    validation()
      .then(
        (res) => {
          if (
            res.tenantId === "" &&
            res.clientId === "" &&
            res.secretId === "" &&
            res.tenantName === ""
          ) {
            //update the tenant table
            const data = {
              tenantName,
              clientId,
              clientSecret: secretId,
              status: isActiveChecked === true ? "Active" : "InActive",
            };
            updateTenantById(tenantId, data)
              .then(
                (res) => {
                  const data = res.data;
                  if (res.status === 200) {
                    setIsValidTenant(true);
                    setIsFormSubmitted(false);
                    onClose("success");
                  } else if (res.status === 500) {
                    setClientInfoErr(data);
                    setIsValidTenant(true);
                    setIsFormSubmitted(false);
                  } else {
                    setClientInfoErr("Unable to update tenant");
                    setIsValidTenant(true);
                    setIsFormSubmitted(false);
                  }
                },
                (err) => {
                  console.error("tenant service update error :", err);
                  setClientInfoErr("Unable to update tenant");
                  setIsValidTenant(true);
                  setIsFormSubmitted(false);
                }
              )
              .catch((err) => {
                console.error("tenant update error :", err);
                setClientInfoErr("Internal server error");
                setIsValidTenant(true);
                setIsFormSubmitted(false);
              });
            console.log("updating the tenant");
          } else {
            setClientInfoErr("validation error");
            setIsValidTenant(true);
            setIsFormSubmitted(false);
          }
        },
        (err) => {
          console.log("unable to valid the tenant : ", err);
          setClientInfoErr(err);
          setIsValidTenant(true);
          setIsFormSubmitted(false);
        }
      )
      .catch((error) => {
        console.log("Internal error : ", error);
        setClientInfoErr("Internal error");
        setIsValidTenant(true);
        setIsFormSubmitted(false);
      });
  };

  return (
    <Dialog onClose={handleClose} open={open} fullWidth disableEscapeKeyDown>
      <DialogTitle>Edit Tenant</DialogTitle>
      <DialogContent>
        <Box component="form" noValidate sx={{ mt: 1 }}>
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <Grid item xs={3}>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  width: 450,
                  padding: 1,
                }}
              >
                <IconTextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="tenantId"
                  label="Tenant ID"
                  value={tenantId}
                  autoFocus
                  onChange={(e) => {
                    formInputChange("tenantId", e.target.value);
                  }}
                  onBlur={handleTenantCheck}
                  name="tenantId"
                  size="small"
                  error={tenantIdErr ? true : false}
                  helperText={tenantIdErr}
                  disabled={true}
                />
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  width: 450,
                  padding: 1,
                }}
              >
                <IconTextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="tenantName"
                  label="Tenant Name"
                  value={tenantName}
                  autoFocus
                  onChange={(e) => {
                    formInputChange("tenantName", e.target.value);
                  }}
                  name="tenantName"
                  size="small"
                  error={tenantNameErr ? true : false}
                  helperText={tenantNameErr}
                />
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  width: 450,
                  padding: 1,
                }}
              >
                <IconTextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="clientId"
                  label="Client ID"
                  name="clientId"
                  value={clientId}
                  onChange={(e) => {
                    formInputChange("clientId", e.target.value);
                  }}
                  size="small"
                  disabled={!isValidTenant}
                  error={clientIdErr ? true : false}
                  helperText={clientIdErr}
                />
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  width: 450,
                  padding: 1,
                }}
              >
                <IconTextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="secretId"
                  label="Secret ID"
                  name="secretId"
                  size="small"
                  value={secretId}
                  onChange={(e) => formInputChange("secretId", e.target.value)}
                  disabled={!isValidTenant}
                  error={secretIdErr ? true : false}
                  helperText={secretIdErr}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Stack direction="row" alignItems="center">
                <Typography variant="body2" component="div" sx={{ mr: 5 }}>
                  Active Status :
                </Typography>
                <JbwSwitch
                  isChecked={handleisActiveChecked}
                  setChecked={isActiveChecked}
                  //id={id.toString()}
                  disabled={disableisActive ? true : false}
                />
              </Stack>
            </Grid>

            <Grid item xs={12}>
              <Typography
                variant="body"
                sx={{ color: "#f44336", fontSize: "10px" }}
              >
                {clientInfoErr}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" spacing={1}>
          <Button
            disabled={isFormSubmitted ? true : false}
            onClick={handleClose}
          >
            Cancel
          </Button>
          {!showSTBtn ? (
            <Button
              // onClick={handleResetPassword}
              onClick={handleUpdate}
              disabled={isFormSubmitted ? true : false}
            >
              Update
            </Button>
          ) : (
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              size="small"
              sx={{
                borderRadius: "50px",
              }}
              onClick={clientConnectHandler}
            >
              Connect to service Titan
            </Button>
          )}
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default TenantsDialog;
