import { Button, IconButton, Snackbar } from "@mui/material";
import { useState } from "react";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";

const CopyToClipboardButton = ({ copyedtext }) => {
  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(true);
    navigator.clipboard.writeText(copyedtext);
  };

  return (
    <>
      <IconButton
        color="secondary"
        onClick={handleClick}
        aria-label="Copy to Clipboard"
      >
        <ContentCopyOutlinedIcon
          sx={{
            fontSize: "1.1rem",
            width: "0.8em",
            height: "0.8em",
          }}
        />
      </IconButton>

      <Snackbar
        open={open}
        onClose={() => setOpen(false)}
        autoHideDuration={2000}
        message="Copied to clipboard"
      />
    </>
  );
};

export default CopyToClipboardButton;
