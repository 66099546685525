import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { Buffer } from "buffer";

const TransmissionDetails = ({ logdata }) => {
  const blobToText = (data) => {
    let str = "";
    if (data) {
      const bufferArray = [Buffer.from(data)];
      str = bufferArray[0]
        .toString("utf-8")
        .replace(/INFO:/g, "</br>INFO:")
        .replace(/ERROR:/g, "</br>ERROR:");
    }

    return <div dangerouslySetInnerHTML={createMarkup(str)} />;
  };

  const createMarkup = (rawText) => {
    return { __html: rawText };
  };

  return (
    <Box
      sx={{
        maxWidth: "100%",
        padding: 2,
        boxShadow: 4,
        borderRadius: 3,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        backgroundColor: "#fff",
      }}
    >
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h6" component="h6">
            Log Details of {logdata?.batchId}
          </Typography>
          <Typography
            variant="div"
            component="div"
            sx={{ mt: 2, maxHeight: "50vh", height: "50vh", overflowY: "auto" }}
          >
            {logdata && logdata.logDetails && logdata.logDetails.data !== null
              ? blobToText(logdata?.logDetails?.data)
              : "No Log Available"}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TransmissionDetails;
