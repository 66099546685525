import {
  Autocomplete,
  Box,
  Dialog,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  TextField,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";

import {
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {
  searchInvoice,
  createAgreementFromInvoice,
} from "../services/agreementServices";
import { throttle } from "lodash";
import TblProductsSearch from "./TblProductsSearch";
import { AuthContext } from "../AuthContext";
const NewAgreementDialog = ({
  open,
  onClose,
  dealerId,
  onCreateDuplicateAgreement,
}) => {
  const { user } = useContext(AuthContext);
  const [disableSaveBtn, setDisableSaveBtn] = useState(false);
  const [disableCancelBtn, setDisableCancelBtn] = useState(false);
  const [typeValue, setTypeValue] = useState("job");
  const [searchKey, setSearchKey] = useState("");
  const [searchResults, setSearchResults] = useState(null);
  const [agrProductCode, setAgreProductCode] = useState(null);
  const [errorMsg, setErrorMsg] = useState("");
  const [infoMsg, setInfoMsg] = useState({
    msg: "Please enter job number and click on search button",
    colorCode: "#6EC207",
  });

  const handleInvoiceJobSearch = (e) => {
    setSearchResults(null);
    e.preventDefault();
    setInfoMsg({
      msg: "Please wait while loading invoice data",
      colorCode: "#FCDE70",
    });
    console.log("searchType", typeValue);
    console.log("dealerId", dealerId);
    console.log("searchKey", searchKey);
    searchInvoice(dealerId, typeValue, searchKey).then((res) => {
      console.log("res", res);
      if (res.data.invoiceData && res.data.invoiceData.length > 0) {
        setInfoMsg(null);
        setSearchResults(res.data.invoiceData[0]);
      } else {
        setInfoMsg({ msg: "No Invoice Details Found", colorCode: "#B8001F" });
      }
    });
  };
  const handleSearchCustomer = (data) => {
    console.log("customer data", data);
    setInfoMsg(null);
    setSearchResults(data);
  };

  const handleProductSelected = (param) => {
    console.log("selected product :", param);
    setInfoMsg(null);
    setAgreProductCode({
      productId: param.productId,
      productCode: param.productCode,
    });
  };

  const handleCreateAgreement = () => {
    console.log("create agreement");
    setDisableCancelBtn(true);
    setDisableSaveBtn(true);
    if (searchResults && agrProductCode) {
      setErrorMsg("");

      console.log({
        inovice: searchResults.invoiceId,
        product: agrProductCode.productCode,
        createdBy: user.name,
      });
      createAgreementFromInvoice(
        dealerId,
        searchResults.invoiceId,
        agrProductCode.productCode,
        user.name
      ).then(
        (res) => {
          console.log(res);
          if (res.status === 200) {
            console.log("create agreement success", res.data);
            resetDig();
            onClose("success");
          } else {
            setDisableCancelBtn(false);
            setDisableSaveBtn(false);
            console.log("unable to create agreement");
            setErrorMsg("unable to create agreement", res.status);
          }
        },
        (error) => {
          setDisableCancelBtn(false);
          setDisableSaveBtn(false);
          console.log("Error in create agreement");
          setErrorMsg("Error in create agreement", error);
        }
      );
    } else {
      setDisableCancelBtn(false);
      setDisableSaveBtn(false);
      console.log("Please select invoice and Plan Id");
      setErrorMsg("Please select invoice and Plan Id");
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "escapeKeyDown" || reason === "backdropClick") {
      return;
    }
    resetDig();
    onClose();
  };

  const resetDig = () => {
    setInfoMsg({
      msg: "Please enter job number and click on search button",
      colorCode: "#6EC207",
    });
    setDisableSaveBtn(false);
    setDisableCancelBtn(false);
    setSearchResults(null);
    setSearchKey("");
    setTypeValue("job");
    setAgreProductCode(null);
    setErrorMsg("");
  };

  const handleTypeChange = (data) => {
    setSearchKey("");
    setSearchResults(null);
    setAgreProductCode(null);
    setTypeValue(data);
    if (data === "job") {
      setInfoMsg({
        msg: "Please enter job number and click on search button",
        colorCode: "#6EC207",
      });
    }
    if (data === "customer") {
      setInfoMsg({ msg: "Please enter customer name", colorCode: "#6EC207" });
    }
    if (data === "location") {
      setInfoMsg({ msg: "Please enter Address", colorCode: "#6EC207" });
    }
  };

  const handleCreateDuplicateAgreement = () => {
    const agreementId = searchResults.agreementId;
    resetDig();
    onCreateDuplicateAgreement({ id: agreementId, ctype: "newaggclose" });
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      PaperProps={{
        component: "div",
      }}
      fullWidth={true}
      maxWidth={"md"}
      disableEscapeKeyDown
    >
      <DialogTitle sx={{ m: 0, p: 2 }}>
        <b>Create Agreement</b>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl>
              <FormLabel>Search Type</FormLabel>
              <RadioGroup
                row
                defaultValue={"job"}
                name="search-type-row-radio-buttons-group"
                value={typeValue}
                onChange={(event) => handleTypeChange(event.target.value)}
              >
                <FormControlLabel
                  value="job"
                  control={<Radio />}
                  label="Job#"
                />
                <FormControlLabel
                  value="customer"
                  control={<Radio />}
                  label="Customer Name"
                />
                <FormControlLabel
                  value="location"
                  control={<Radio />}
                  label="Address"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            {typeValue === "job" ? (
              <>
                <TextField
                  id="standard-basic"
                  label="Job Search"
                  variant="standard"
                  sx={{ width: "40%" }}
                  value={searchKey}
                  inputProps={{ autoComplete: "off" }}
                  onChange={(e) => {
                    setSearchKey(e.target.value);
                  }}
                />
                <IconButton
                  color="secondary"
                  variant="outlined"
                  aria-label="search job"
                  sx={{ marginTop: "15px" }}
                  onClick={handleInvoiceJobSearch}
                >
                  <SearchIcon sx={{ fontSize: "25px" }} />
                </IconButton>
              </>
            ) : typeValue === "customer" ? (
              <TblInvoiceCustomerSearch
                searchValue={searchKey}
                onInvoiceSelected={handleSearchCustomer}
                searchType={typeValue}
                dealerId={dealerId}
              />
            ) : (
              <TblInvoiceLocationSearch
                searchValue={searchKey}
                onInvoiceSelected={handleSearchCustomer}
                searchType={typeValue}
                dealerId={dealerId}
              />
            )}
          </Grid>
          <Grid item xs={12}>
            {searchResults && searchResults?.isAgreementCreated === 1 && (
              <Typography
                component="subtitle1"
                variant="subtitle1"
                color="#B8001F"
              >
                Agreement is already created, Click here
                <IconButton
                  color="secondary"
                  variant="outlined"
                  aria-label="search job"
                  sx={{ marginTop: "0px" }}
                  onClick={handleCreateDuplicateAgreement}
                >
                  <ContentCopyIcon sx={{ fontSize: "25px" }} />
                </IconButton>
                to create duplicate agreement
              </Typography>
            )}{" "}
            {searchResults && searchResults?.isAgreementCreated === 0 && (
              <Grid container spacing={2}>
                <Grid item xs={5}>
                  <Typography>
                    <b>Job ID:</b> {searchResults?.jobNumber}
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography>
                    <b>Invoice ID:</b> {searchResults?.invoiceId}
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography>
                    <b>Customer Name:</b> {searchResults?.customer}
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography>
                    <b>Address:</b>
                    {searchResults?.location ? searchResults?.location : ""}.
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <TblProductsSearch
                    searchValue={agrProductCode?.productCode}
                    onProductSelected={handleProductSelected}
                  />
                </Grid>
              </Grid>
            )}
            {infoMsg && (
              <Typography
                component="subtitle1"
                variant="subtitle1"
                color={infoMsg.colorCode}
              >
                {infoMsg.msg}
              </Typography>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container spacing={2}>
          <Grid item>
            {errorMsg && (
              <Typography sx={{ color: "red" }}>{errorMsg}</Typography>
            )}
          </Grid>
          <Box sx={{ flexGrow: 1 }} />
          <Grid item>
            <Button onClick={handleCreateAgreement} disabled={disableSaveBtn}>
              Save
            </Button>
            <Button onClick={handleClose} disabled={disableCancelBtn}>
              Cancel
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

const TblInvoiceCustomerSearch = ({
  searchValue,
  onInvoiceSelected,
  searchType,
  dealerId,
}) => {
  //console.log("search value:", searchValue);
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);
  useEffect(() => {
    setValue({ customer: searchValue });
  }, [searchValue]);
  //console.log("value :", value);
  const fetch = React.useMemo(
    () =>
      throttle((request) => {
        if (request?.input.length > 2) {
          const getData = searchInvoice(dealerId, searchType, request?.input);
          getData.then((res) => {
            console.log("customer data memo :", res.data.invoiceData);
            setOptions(res.data.invoiceData);
            return res.data.invoiceData;
          });
        }
      }, 200),
    []
  );

  const onValueSelect = (data) => {
    console.log("selected Data", data);
    onInvoiceSelected(data);
  };

  React.useEffect(() => {
    let active = true;
    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = [];
        if (value) {
          newOptions = [value];
        }
        if (results) {
          newOptions = [...newOptions, ...results];
        }
        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  return (
    <Autocomplete
      id="tbl-Invoice-customer-search"
      freeSolo
      sx={{ width: 250, borderRadius: 50 }}
      getOptionLabel={(option) => {
        return `${option.customer ? option.customer : ""}`;
      }}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      loading={true}
      loadingText={"Loading Customer's..."}
      isOptionEqualToValue={(option, value) =>
        option.customer === value.customer
      }
      noOptionsText={
        "No Invoice Found with given Customer Name , Please enter Customer Name "
      }
      onChange={(event, newValue) => {
        console.log("newValue", newValue);
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue ? newValue : { customer: searchValue });
        onValueSelect(newValue ? newValue : { customer: searchValue });
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          sx={{ fontSize: 12 }}
          variant="standard"
          label="Customer Search"
        />
      )}
      renderOption={(props, option) => (
        <Box component="li" sx={{ fontSize: 16 }} {...props} key={option.id}>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={0.5}
          >
            <p>Customer : {option.customer}</p>
          </Stack>
        </Box>
      )}
    />
  );
};
const TblInvoiceLocationSearch = ({
  searchValue,
  onInvoiceSelected,
  searchType,
  dealerId,
}) => {
  //console.log("search value:", searchValue);
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);
  useEffect(() => {
    setValue({ location: searchValue });
  }, [searchValue]);
  //console.log("value :", value);
  const fetch = React.useMemo(
    () =>
      throttle((request) => {
        if (request?.input.length > 2) {
          const getData = searchInvoice(dealerId, searchType, request?.input);
          getData.then((res) => {
            console.log("Location data memo :", res.data.invoiceData);
            setOptions(res.data.invoiceData);
            return res.data.invoiceData;
          });
        }
      }, 200),
    []
  );

  const onValueSelect = (data) => {
    console.log("selected Data", data);
    onInvoiceSelected(data);
  };

  React.useEffect(() => {
    let active = true;
    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = [];
        if (value) {
          newOptions = [value];
        }
        if (results) {
          newOptions = [...newOptions, ...results];
        }
        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  return (
    <Autocomplete
      id="tbl-Invoice-location-search"
      freeSolo
      sx={{ width: 250, borderRadius: 50 }}
      getOptionLabel={(option) => {
        return `${option.location ? option.location : ""}`;
      }}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      loading={true}
      loadingText={"Loading Location's..."}
      isOptionEqualToValue={(option, value) =>
        option.location === value.location
      }
      noOptionsText={
        "No Invoice Found with given location Name , Please enter Location."
      }
      onChange={(event, newValue) => {
        console.log("newValue", newValue);
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue ? newValue : { location: searchValue });
        onValueSelect(newValue ? newValue : { location: searchValue });
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          sx={{ fontSize: 12 }}
          variant="standard"
          label="Address Search"
        />
      )}
      renderOption={(props, option) => (
        <Box component="li" sx={{ fontSize: 16 }} {...props} key={option.id}>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={0.5}
          >
            <p>Address : {option.location}</p>
          </Stack>
        </Box>
      )}
    />
  );
};

export default NewAgreementDialog;
