import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext } from "react";
import {
  getAgreementEquipmentsList,
  addAgreementEquipments,
} from "../services/agreementServices";
import { useState } from "react";
import { useEffect } from "react";
import AgreementEquipmentForm from "./AgreementEquipmentForm";
import { AuthContext } from "../AuthContext";
import TblProductsSearch from "./TblProductsSearch";

const AgmEquiplmentDialog = (props) => {
  const { onClose, open, agreementId, optype, productCode, poNumber } = props;
  const { user } = useContext(AuthContext);
  const [equipmentList, setEquipmentList] = useState([]);
  const [saveError, setSaveError] = useState("");
  const [agrProductCode, setAgreProductCode] = useState(null);
  const [agrPoNumber, setAgrPoNumber] = useState(null);
  const [formStatus, setFormStatus] = useState(false);
  const [loadingMsg, setLoadingMsg] = useState("");
  const [showCloseBtn, setShowCloseBtn] = useState(false);
  // console.log("product code :", productCode);

  useEffect(() => {
    setAgreProductCode(productCode);
  }, [productCode]);

  useEffect(() => {
    setAgrPoNumber(poNumber);
  }, [poNumber]);

  //console.log("agrProductCode :", agrProductCode);
  // const createEquipmentList = () => {
  //   const eqList = [];
  //   for (let i = 1; i <= 5; i++) {
  //     eqList.push({ id: i, manufacturer: "", modelNo: "", serialNumber: "" });
  //   }
  //   return eqList;
  // };

  const getEquipments = (agreementId) => {
    console.log("AgmEquiplmentDialog", agreementId);
    setEquipmentList([]);
    setLoadingMsg("Loading Equipments. Please Wait..");
    getAgreementEquipmentsList(agreementId)
      .then(
        (res) => {
          const eqList = res.data.body;
          const statusCode = res.status;
          if (statusCode === 200) {
            console.log("AgmEquiplmentDialog equipment list :", eqList);
            if (eqList && eqList.length > 0) {
              setEquipmentList(eqList);
              setLoadingMsg("");
            } else {
              // const tempEqlist = createEquipmentList();
              // setEquipmentList(tempEqlist);
              setShowCloseBtn(true);
              setLoadingMsg("No equipments found");
            }
          }
        },
        (err) => {
          console.log("unable to load equipment list", err);
          setSaveError("unable to load equipment list");
        }
      )
      .catch((err) => {
        console.log("Equipment List Internal Server Error", err);
        setSaveError("Internal Server error");
      });
  };

  useEffect(() => {
    if (optype === "update") {
      getEquipments(agreementId);
    } else {
      setEquipmentList([
        {
          id: 1,
          manufacturer: "",
          modelNo: "",
          serialNumber: "",
          isDeleted: false,
        },
      ]);
    }

    setFormStatus(false);
  }, [agreementId, optype]);

  const handleClose = (event, reason) => {
    if (reason === "escapeKeyDown" || reason === "backdropClick") {
      return;
    }
    setSaveError("");
    setShowCloseBtn(false);
    onClose("close");
  };

  //   const handleEquipmentListChanged = (eqList) => {
  //     console.log("updated equipment list", eqList);
  //     setEquipmentList((values) => eqList);
  //   };

  const handleSave = (eqList) => {
    setSaveError("");
    setFormStatus(true);
    if (optype === "create") {
      eqList = eqList
        .map((eq) => ({ ...eq, id: "", createdBy: user.name }))
        .filter((eq) => eq.manufacturer !== "");
    } else {
      eqList = eqList
        .map((eq) => {
          if (eq.eqtype === "new") {
            return { ...eq, id: "", createdBy: user.name };
          } else {
            return { ...eq, modifiedBy: user.name };
          }
        })
        .filter((eq) => eq.manufacturer !== "");
    }
    console.log("json parse:", JSON.parse(JSON.stringify(eqList)));
    const reqJson = {
      agreementId: agreementId,
      optype: optype,
      equipmentList: eqList,
      agrPoNumber,
      agrProductCode,
    };
    addAgreementEquipments(agreementId, reqJson, optype)
      .then(
        (res) => {
          console.log("save data :", res.data);
          const statusCode = res.status;
          if (statusCode === 200) {
            getEquipments(agreementId);
            setFormStatus(false);
            onClose("success");
          } else if (statusCode === 500) {
            setFormStatus(false);
            console.log("unable to save the equipment", res.data);
            setSaveError("Error in saving Equipment list");
          }
        },
        (err) => {
          setFormStatus(false);
          console.log("unable to save the equipment", err);
          setSaveError("unable to save the equipment");
        }
      )
      .catch((err) => {
        setFormStatus(false);
        console.error("Internal server error :", err);
        setSaveError("Internal Server error");
      });
  };
  const handleProductSelected = (param) => {
    console.log("selected product :", param);
    setAgreProductCode(param.productCode);
  };

  const handlePoNumberChange = (args) => {
    console.log("PoNumber change :", args);
    setAgrPoNumber(args);
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      maxWidth="600px"
      disableEscapeKeyDown
    >
      <DialogTitle>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            {optype === "create" ? "Add " : "Edit "}Equipment
          </Grid>

          <Grid item xs={8} align="right">
            <Stack direction="row" spacing={2}>
              {/* <TextField
                id="product-code"
                label="Product Code"
                variant="standard"
                value={productCode}
              /> */}

              <TextField
                id="Po-no"
                label="Po Number"
                variant="standard"
                value={agrPoNumber}
                onChange={(e) => handlePoNumberChange(e.target.value)}
              />
              <TblProductsSearch
                searchValue={agrProductCode}
                onProductSelected={handleProductSelected}
              />
            </Stack>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        {equipmentList.length > 0 ? (
          <AgreementEquipmentForm
            equipmentList={equipmentList}
            optype={optype}
            onClose={handleClose}
            onSave={handleSave}
            formStatus={formStatus}
          />
        ) : (
          <Grid container>
            <Grid item xs={12} sx={{ maxHeight: "600px", maxWidth: "600px" }}>
              <Typography
                color="secondary"
                variant="caption"
                align="left"
                sx={{ fontSize: "14px" }}
              >
                {loadingMsg}
              </Typography>
            </Grid>
          </Grid>
        )}

        {saveError && (
          <Typography
            variant="body"
            sx={{ color: "#f44336", fontSize: "10px" }}
          >
            {saveError}
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        {showCloseBtn && <Button onClick={handleClose}>Cancel</Button>}
        {/* <Button onClick={handleSave}>Save</Button> */}
      </DialogActions>
    </Dialog>
  );
};

export default AgmEquiplmentDialog;
