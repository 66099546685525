import axios from "./configs/axiosConfig";

export const getAgreementList = async (dealerId) => {
  const url = `agreements/dealer/${dealerId}`;
  return await axios.get(url);
};

export const getAgreementEquipmentsList = async (agreementId) => {
  const url = `agreements/${agreementId}/equipments`;
  return await axios.get(url);
};

export const getAgreementDetails = async (agreementId) => {
  const url = `agreements/${agreementId}/agreementdetails`;
  return await axios.get(url);
};

export const createDupilcateAgreement = async (
  agreementId,
  agreementDetails
) => {
  const url = `agreements/${agreementId}/duplicateagreement`;
  return await axios.post(url, agreementDetails);
};

export const addAgreementEquipments = async (
  agreementId,
  equipmentList,
  optype
) => {
  const url = `agreements/${agreementId}/equipments?optype=${optype}`;
  return await axios.post(url, equipmentList);
};
export const submitAgreementEquipments = async (agreementId) => {
  const url = `agreements/${agreementId}/equipmentsync`;
  return await axios.put(url);
};

export const searchInvoice = async (dealerId, searchType, searchKey) => {
  const url = `agreements/dealer/${dealerId}/invoicesearch?searchType=${searchType}&searchKey=${searchKey}`;
  return await axios.get(url);
};

export const createAgreementFromInvoice = async (
  dealerId,
  invoiceid,
  productcode,
  createdBy
) => {
  const url = `agreements/dealer/${dealerId}/newinvoiceagreement`;
  return await axios.post(url, { invoiceid, productcode, createdBy });
};
