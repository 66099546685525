import { Box, Container, Grid } from "@mui/material";
import React from "react";

function JBWarrantiesFrame() {
  return (
    <Box component="main">
      <Container maxWidth="xl">
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} lg={12}>
            <Box
              sx={{
                maxWidth: "100%",
                padding: 1,
                boxShadow: 4,
                borderRadius: 3,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                backgroundColor: "#fff",
              }}
            >
              <iframe
                src="https://www.jbandassociates.biz/member/test/claim360.aspx?userid=2"
                title="jbwclaims"
                height="600px"
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default JBWarrantiesFrame;
