import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "../components/Sidebar";
import { Box, Toolbar, colors } from "@mui/material";
import TopBar from "../components/TopBar";

const HomeLayout = () => {
  const [isOpen, setIsOpen] = useState(false);

  const getData = (data) => {
    setIsOpen(data);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <TopBar isOpen={isOpen} />
      <Box>
        <Sidebar navOpen={getData} />
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: `calc(100% - ${"300px"})`,
          minHeight: "100vh",
          backgroundColor: colors.grey["100"],
        }}
      >
        <Toolbar />
        <Outlet />
      </Box>
    </Box>
  );
};

export default HomeLayout;
