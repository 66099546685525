import { LinearProgress } from "@mui/material";
import { amber, grey } from "@mui/material/colors";
import React from "react";

const ClientProgressBar = ({ value }) => {
  return (
    <LinearProgress
      variant="determinate"
      sx={{
        backgroundColor: grey[300],
        "& .MuiLinearProgress-bar": {
          backgroundColor: amber[700],
        },
        height: 7,
        borderRadius: 50,
        width: 350,
      }}
      value={value}
    />
  );
};

export default ClientProgressBar;
