import { Card, CardContent, Grid, Typography } from "@mui/material";
import React from "react";

const DealerCards = (props) => {
  const { sx, value, imgsrc, titletxt, titleFontSize = "30px" } = props;
  return (
    <Card sx={sx} raised={true}>
      <CardContent sx={{ p: 1, ml: 2 }}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography
              color="text.secondary"
              variant="caption"
              align="left"
              sx={{ fontSize: titleFontSize }}
            >
              {value}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <img src={imgsrc} alt={titletxt} width="84px" height="84px" />
          </Grid>
          <Grid item xs={12}>
            <Typography
              color="text.secondary"
              variant="caption"
              align="left"
              sx={{ fontSize: "14px" }}
            >
              {titletxt}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default DealerCards;
