import React, { useState } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { ThemeProvider } from "@mui/material/styles";
import IconTextField from "../components/IconTextField";
import { EmailOutlined } from "@mui/icons-material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import loginbg from "../assets/login-image.png";
import { useNavigate } from "react-router-dom";
import { userPasswordReset } from "../services/signupService";
import appTheme from "../themes/theme";

const VerifyUser = () => {
  const Navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [serviceErr, setServiceErr] = useState("");
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const formInputChange = (formField, value) => {
    if (formField === "email") {
      setEmail(value);
    }
  };

  const validation = () => {
    return new Promise((resolve, reject) => {
      if (email === "") {
        setEmailErr("Email is Required");
        resolve({
          email: "Email is Required",
        });
      } else {
        resolve({
          email: "",
        });
      }
      reject("");
    });
  };

  const handleClick = (event) => {
    event.preventDefault();
    setEmailErr("");
    setServiceErr("");
    setIsFormSubmitted(true);
    validation()
      .then(
        (res) => {
          if (res.email === "") {
            userPasswordReset({ email })
              .then(
                (resData) => {
                  console.log(resData);
                  const body = resData.data;
                  const statusCode = resData.status;
                  if (statusCode === 200) {
                    handleBackToLoging();
                    setIsFormSubmitted(false);
                  } else {
                    console.log("message :", JSON.parse(body).message);
                    setIsFormSubmitted(false);
                    setEmailErr(JSON.parse(body).message);
                  }
                },
                (err) => {
                  console.log(JSON.stringify(err));
                  setIsFormSubmitted(false);
                  setEmailErr("Cannot verify email");
                }
              )
              .catch((err) => {
                console.log(err);
                setIsFormSubmitted(false);
                setServiceErr("Internal Server Error");
              });
          } else {
            setIsFormSubmitted(false);
            setServiceErr("Cannot verify email");
          }
        },
        (err) => {
          console.log(err);
          setIsFormSubmitted(false);
          setEmailErr("Invalid Email");
        }
      )
      .catch((err) => {
        console.log(err);
        setIsFormSubmitted(false);
        setServiceErr("Interal Errorr");
      });
  };

  const handleBackToLoging = () => {
    Navigate("/");
  };

  return (
    <ThemeProvider theme={appTheme}>
      <Grid container sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `url(${loginbg})`,
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />

        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            m="auto"
            sx={{
              width: 300,
              my: 8,
              mx: 4,
              ml: 20,
              mt: 30,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography component="h1" variant="h5">
              Hello!
            </Typography>
            <Typography component="div" variant="h6" sx={{ fontSize: "14px" }}>
              For Reset Password Enter Registered Email
            </Typography>
            <Box component="form" noValidate sx={{ mt: 1 }}>
              <IconTextField
                iconStart={
                  <EmailOutlined sx={{ color: "#bdbdbd", fontSize: 20 }} />
                }
                margin="normal"
                required
                fullWidth
                id="email"
                placeholder="Email Address"
                name="email"
                autoComplete="email"
                value={email}
                onChange={(e) => formInputChange("email", e.target.value)}
                error={emailErr ? true : false}
                helperText={emailErr}
                size="small"
                disabled={isFormSubmitted ? true : false}
              />

              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  mt: 3,
                  mb: 2,
                  borderRadius: "50px",
                }}
                onClick={handleClick}
                size="small"
                disabled={isFormSubmitted ? true : false}
              >
                Reset Password
              </Button>
              <Button
                type="submit"
                fullWidth
                variant="text"
                sx={{
                  borderRadius: "50px",
                }}
                onClick={handleBackToLoging}
                startIcon={<ArrowBackIcon />}
                size="small"
                disabled={!isFormSubmitted ? false : true}
              >
                Back to Login Page
              </Button>
              <Typography
                variant="body"
                sx={{ color: "#f44336", fontSize: "10px" }}
              >
                {serviceErr}
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default VerifyUser;
