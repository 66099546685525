import {
  Badge,
  Box,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import MailIcon from "@mui/icons-material/Mail";

import { getEmailNotificationList } from "../services/emailNotifcationService";
import { AuthContext } from "../AuthContext";
import { useNavigate } from "react-router-dom";

const EmailNotificationBtn = () => {
  const { setMailMessage, group } = useContext(AuthContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const openEmailNotifcations = Boolean(anchorEl);
  const [selectedDealer, setSelectedDealer] = useState(null);
  const [emailNotificationList, setEmailNotificationList] = useState([]);
  const [notificationCount, setNotificationCount] = useState(0);
  const [resultMsg, setResultMsg] = useState("");

  let navigate = useNavigate();

  useEffect(() => {
    const dealer = localStorage.getItem("dealer");
    if (dealer) {
      setSelectedDealer(JSON.parse(dealer));
    }
  }, []);

  const getEmailList = (dealerId) => {
    getEmailNotificationList(dealerId)
      .then((res) => {
        const data = res.data;
        const statusCode = res.status;
        if (statusCode === 200) {
          if (data && data.length > 0) {
            setEmailNotificationList(data);
            setNotificationCount(data.length);
          } else {
            setResultMsg("Not Notifications");
          }
        } else {
          setResultMsg("unable to load notifications");
        }
      })
      .catch((err) => {
        console.error("Internal server error : ", err);
      });
  };

  useEffect(() => {
    //const dealerId = selectedDealer ? selectedDealer.id : 0;
    if (group === "jbw-admin") {
      getEmailList(0);
    } else {
      if (selectedDealer) getEmailList(selectedDealer?.dealerId);
    }
  }, [selectedDealer, group]);

  const handleEmailNofiticationClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleEmailNotificationClose = (email) => {
    setAnchorEl(null);
  };
  const handleSelectedMail = (email) => {
    setAnchorEl(null);
    console.log("selected email :", email);
    setMailMessage(email);
    navigate("/home/todohome");
  };

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <Tooltip title="Email Notification">
          <IconButton
            onClick={handleEmailNofiticationClick}
            size="small"
            sx={{ ml: 2, color: "#a8a8a8d4", marginRight: "15px" }}
            aria-controls={
              openEmailNotifcations ? "email-notifications" : undefined
            }
            aria-haspopup="true"
            aria-expanded={openEmailNotifcations ? "true" : undefined}
            aria-label={`show ${notificationCount} new mails`}
          >
            <Badge badgeContent={notificationCount} color="error">
              <MailIcon fontSize="small" />
            </Badge>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="email-notifications"
        open={openEmailNotifcations}
        disableScrollLock={false}
        onClose={handleEmailNotificationClose}
        onClick={handleEmailNotificationClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "auto",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {emailNotificationList &&
          emailNotificationList.map((en) => {
            return (
              <MenuItem key={en.id} onClick={() => handleSelectedMail(en)}>
                <ListItemIcon vertical="secondary">
                  <MailIcon fontSize="small" />
                </ListItemIcon>
                Dealer Id :{en.id} - Total Messages:{en.subject}
              </MenuItem>
            );
          })}
      </Menu>
    </>
  );
};

export default EmailNotificationBtn;
