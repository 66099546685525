import axios from "./configs/axiosConfig";

export const getTenantById = async (id) => {
  const url = `/tenant/${id}`;
  return await axios.get(url);
};

export const getTenantByfDealerId = async (dealerid) => {
  const url = `/tenant/dealer/${dealerid}`;
  return await axios.get(url);
};

export const updateTenantById = async (id, data) => {
  const url = `/tenant/${id}`;
  return await axios.patch(url, data);
};
