import React, { useState, useEffect, useContext } from "react";
import { getEmailNotificationList } from "../services/emailNotifcationService";
import {
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  InputLabel,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import MailIcon from "@mui/icons-material/Mail";
import { AuthContext } from "../AuthContext";
import AgmEquiplmentDialog from "../components/AgmEquiplmentDialog";
import AgreementList from "../components/AgreementList";
const ToDoHome = () => {
  const { mailMessage, user, group } = useContext(AuthContext);
  const [emailNotificationList, setEmailNotificationList] = useState([]);
  const [notificationCount, setNotificationCount] = useState(0);
  const [selectedDealer, setSelectedDealer] = useState(
    JSON.parse(localStorage.getItem("dealer"))
  );
  const [resultMsg, setResultMsg] = useState("");
  const [emailMessage, setEmailMessage] = useState("Please select the message");
  const [selectedEmail, setSelectedEmail] = useState(mailMessage);
  const [selectedAgreementId, setSelectedAgreementId] = useState("");
  const [selectedDealerId, setSelectedDealerId] = useState(0);
  const [openAgmEquiDialog, setOpenAgmEquiDialog] = useState(false);
  const [optype, setOptype] = useState("");
  const [showAgmGrid, setShowAgmGrid] = useState(false);
  const [loadingMsg, setLoadingMsg] = useState("No Actions..");
  const [adminDealers, setAdminDealers] = useState([]);
  const [selectedAdminDealer, setSelectedAdminDealer] = useState(null);

  // useEffect(() => {
  //   setSelectedEmail(mailMessage);
  // }, [mailMessage]);

  // useEffect(() => {
  //   const dealer = localStorage.getItem("dealer");
  //   if (dealer) {
  //     setSelectedDealer(JSON.parse(dealer));
  //   }
  // }, []);

  const handleAgmEquiDialogClose = (data) => {
    console.log("close called :", data);
    setOpenAgmEquiDialog(false);
    setOptype("");
  };

  const handleOpenEquiDialog = () => {
    // setOptype("update");
    // setOpenAgmEquiDialog(true);
    setShowAgmGrid(true);
  };

  const getEmailList = (dealerId) => {
    getEmailNotificationList(dealerId)
      .then((res) => {
        const data = res.data;
        const statusCode = res.status;
        if (statusCode === 200) {
          if (data && data.length > 0) {
            setEmailNotificationList(data);
            setNotificationCount(data.length);
            loadSelectedEmail(data);
          } else {
            setResultMsg("Not Notifications");
          }
        } else {
          setResultMsg("unable to load notifications");
        }
      })
      .catch((err) => {
        console.error("Internal server error : ", err);
      });
  };

  const loadSelectedEmail = (emailList) => {
    if (selectedEmail) {
      const loadedEmail = emailList.findIndex(
        (er) => er.id === selectedEmail.id
      );
      if (loadedEmail > -1) {
        console.log(
          `loading email message with index ${loadedEmail} and email id : ${emailList[loadedEmail].id}`
        );
        setSelectedIndex(loadedEmail);
        setEmailMessage(emailList[loadedEmail].body);
        setSelectedAgreementId(emailList[loadedEmail].agreementId);
      }
    } else {
      setSelectedIndex(0);
      setEmailMessage(emailList[0].body);
      setSelectedAgreementId(emailList[0].agreementId);
    }
  };

  const loadAdminDealersSelectList = () => {
    getEmailNotificationList(0)
      .then((res) => {
        const data = res.data;
        const statusCode = res.status;
        if (statusCode === 200) {
          if (data && data.length > 0) {
            setAdminDealers(data);
            if (!selectedEmail) {
              getEmailList(data[0].id);
              setSelectedAdminDealer(data[0].id);
            } else {
              setSelectedAdminDealer(selectedEmail.id);
            }
          } else {
            setResultMsg("Not Notifications");
          }
        } else {
          setResultMsg("unable to load notifications");
        }
      })
      .catch((err) => {
        console.error("Internal server error : ", err);
      });
  };

  useEffect(() => {
    if (group === "jbw-admin") {
      if (selectedEmail) {
        getEmailList(selectedEmail.id);
        loadAdminDealersSelectList();
      } else {
        loadAdminDealersSelectList();
      }
    } else {
      if (selectedDealer) getEmailList(selectedDealer.id);
    }
  }, [selectedDealer, group, selectedEmail]);

  const handleAdminDealerChange = (event) => {
    const dealerId = event.target.value;
    setSelectedAdminDealer(dealerId);
    //setEmailNotificationList([]);
    setEmailMessage("Please select the message");
    setSelectedAgreementId("");
    setShowAgmGrid(false);
    if (dealerId) {
      getEmailList(dealerId);
    }
  };

  const [selectedIndex, setSelectedIndex] = React.useState(1);

  const handleListItemClick = (event, index, msg, aId, dealerId) => {
    setSelectedIndex(index);
    console.log("select message :", msg);
    setEmailMessage(msg);
    setSelectedDealerId(dealerId);
    setSelectedAgreementId(aId);
    setShowAgmGrid(false);
    handleOpenEquiDialog();
  };
  return (
    <>
      {emailNotificationList.length > 0 ? (
        <Box component="main">
          <Container maxWidth="xl">
            <Grid container spacing={1}>
              <Grid item xs={4} sm={4} lg={4}>
                <Box
                  sx={{
                    maxWidth: "100%",
                    padding: 2,
                    boxShadow: 4,
                    borderRadius: 3,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    backgroundColor: "#fff",
                  }}
                >
                  <Stack
                    direction="row"
                    spacing={10}
                    justifyContent="center"
                    alignItems="center"
                  >
                    {group === "jbw-admin" && (
                      <Box>
                        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                          <InputLabel id="dealer-select-small-label">
                            Dealers
                          </InputLabel>
                          <Select
                            labelId="dealer-select-small-label"
                            id="dealer-select-small"
                            value={selectedAdminDealer}
                            label="Dealers"
                            onChange={handleAdminDealerChange}
                          >
                            {adminDealers.map((ad) => {
                              return (
                                <MenuItem key={ad.id} value={ad.id}>
                                  {ad.id} - {ad.dealerName}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Box>
                    )}
                    <Box>Total Messages : {notificationCount}</Box>
                  </Stack>

                  <List
                    component="nav"
                    aria-label="mailList"
                    sx={{
                      width: "100%",
                      maxWidth: 400,
                      bgcolor: "background.paper",
                      position: "relative",
                      overflow: "auto",
                      maxHeight: 250,
                      height: 250,
                      "& ul": { padding: 0 },
                    }}
                  >
                    {emailNotificationList.map((eml, index) => {
                      return (
                        <Box key={eml.id}>
                          <ListItemButton
                            selected={selectedIndex === index}
                            onClick={(event) =>
                              handleListItemClick(
                                event,
                                index,
                                eml.body,
                                eml.agreementId,
                                eml.dealerId
                              )
                            }
                          >
                            <ListItemIcon>
                              <MailIcon />
                            </ListItemIcon>
                            <ListItemText
                              primary={eml.subject}
                              //secondary={`id: ${eml.id}, agmid ${eml.agreementId}`}
                            />
                          </ListItemButton>
                          <Divider />
                        </Box>
                      );
                    })}
                  </List>
                </Box>
              </Grid>
              <Grid item xs={8} sm={8} lg={8}>
                <Box
                  sx={{
                    maxWidth: "100%",
                    padding: 4,
                    boxShadow: 4,
                    borderRadius: 3,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    backgroundColor: "#fff",
                    maxHeight: 335,
                    height: 335,
                  }}
                >
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} lg={12} sx={{ height: "90%" }}>
                      {emailMessage}
                    </Grid>
                    {/* <Grid item xs={12} sm={6} lg={12}>
                      {selectedAgreementId && (
                        <Button onClick={handleOpenEquiDialog}>
                          Show Agreements
                        </Button>
                      )}
                    </Grid> */}
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} lg={12}>
                {showAgmGrid && (
                  <AgreementList
                    displayType="dealers"
                    dealerId={selectedDealerId}
                    filterAgreementId={selectedAgreementId}
                  />
                )}
              </Grid>
            </Grid>
          </Container>
          {/* <AgmEquiplmentDialog
            onClose={handleAgmEquiDialogClose}
            open={openAgmEquiDialog}
            agreementId={selectedAgreementId}
            optype={optype}
            userName={user.name}
          /> */}
        </Box>
      ) : (
        <Box component="main">
          <Container maxWidth="xl">
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} lg={12}>
                <Box
                  sx={{
                    maxWidth: "100%",
                    padding: 2,
                    boxShadow: 4,
                    borderRadius: 3,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    backgroundColor: "#fff",
                  }}
                >
                  <Typography variant="h6" component="h6">
                    {loadingMsg}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
      )}
    </>
  );
};

export default ToDoHome;
