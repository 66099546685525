import { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { AuthContext } from "./AuthContext";

function RouteGuard() {
  const { user, isLoading } = useContext(AuthContext);

  if (isLoading) {
    return <></>;
  }

  if (!user) {
    return <Navigate to="/" replace />;
  }

  return <Outlet />;
}

export default RouteGuard;
