import React, { useContext, useEffect, useMemo, useState } from "react";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import ContentPasteOffIcon from "@mui/icons-material/ContentPasteOff";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
  gridFilteredSortedRowIdsSelector,
  selectedGridRowsSelector,
} from "@mui/x-data-grid";
import {
  Button,
  LinearProgress,
  Paper,
  Stack,
  Tooltip,
  Typography,
  darken,
  lighten,
  styled,
} from "@mui/material";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import STLocationSearchPopup from "./STLocationSearchPopup";
import AddLocationIcon from "@mui/icons-material/AddLocation";
import {
  getAgeementMatchDataList,
  approveAgreements,
} from "../services/agreementMatchDataService.js";
import { AuthContext } from "../AuthContext.jsx";
import moment from "moment";

const getBackgroundColor = (color, mode) =>
  mode === "dark" ? darken(color, 0.7) : lighten(color, 0.7);

const getHoverBackgroundColor = (color, mode) =>
  mode === "dark" ? darken(color, 0.6) : lighten(color, 0.6);

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  "& .super-app-theme--Open": {
    backgroundColor: getBackgroundColor(
      theme.palette.mousehovergrey.main,
      theme.palette.mode
    ),
    "&:hover": {
      backgroundColor: getHoverBackgroundColor(
        theme.palette.mousehovergrey.hover,
        theme.palette.mode
      ),
    },
  },
}));

const AgreementSyncGrid = ({ dealer, onEmptyDataList }) => {
  const { user, group } = useContext(AuthContext);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedAgreement, setSelectedAgreement] = useState(null);
  const [approveState, setApproveStatee] = useState([]);
  const [pageState, setPageState] = useState({
    rows: [],
    total: 0,
  });
  const [loading, setLoading] = useState(true);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 50,
  });
  const fetchData = async (paginationModel) => {
    setLoading(true);
    setPageState({ rows: [], total: 0 });
    const response = await getAgeementMatchDataList(
      dealer,
      paginationModel.page,
      paginationModel.pageSize
    );

    console.log("list response :", response);
    const json = response.data;
    if (json && json.totalCount === 0) {
      onEmptyDataList(true);
    } else {
      onEmptyDataList(false);
    }
    setPageState((old) => ({
      ...old,
      rows: json.data,
      total: json.totalCount,
    }));
    setLoading(false);
  };
  useEffect(() => {
    fetchData(paginationModel);
  }, [paginationModel, dealer]);

  const columns = [
    {
      field: "dealerId",
      headerName: "DealerId",
      width: 100,
      filterable: false,
      sortable: false,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "agreementId",
      headerName: "JBWAgreementId",
      width: 150,
      sortable: false,
      filterable: false,
      headerAlign: "center",
      renderCell: (params) => {
        const purchaseDate = params.row.purchaseDate
          ? moment(params.row.purchaseDate).format("MM/DD/YYYY")
          : "NA";
        const startDate = params.row.startDate
          ? moment(params.row.startDate).format("MM/DD/YYYY")
          : "NA";
        const expirationDate = params.row.expirationDate
          ? moment(params.row.expirationDate).format("MM/DD/YYYY")
          : "NA";
        const infoItems = [
          `Purchase Date : ${purchaseDate}`,
          `Start Date : ${startDate}`,
          `Expiration Date :${expirationDate}`,
        ];
        const tip = infoItems.join("\n");
        return (
          <Tooltip title={<div style={{ whiteSpace: "pre-line" }}>{tip}</div>}>
            <span className="table-cell-trucate">{params.value}</span>
          </Tooltip>
        );
      },
      align: "center",
    },
    {
      field: "customerName",
      headerName: "Customer Name",
      width: group === "jbw-dealer" ? 250 : 150,
      sortable: false,
      headerAlign: "center",
      // align: "center",
      renderCell: (params) => {
        const phoneNumber = params.row.phoneNumber
          ? params.row.phoneNumber
          : "NA";
        const email = params.row.email ? params.row.email : "NA";
        const infoItems = [`Phone Number : ${phoneNumber}`, `email : ${email}`];
        const tip = infoItems.join("\n");
        return (
          <Tooltip title={<div style={{ whiteSpace: "pre-line" }}>{tip}</div>}>
            <span className="table-cell-trucate">{params.value}</span>
          </Tooltip>
        );
      },
    },
    {
      field: "street",
      headerName: "Location",
      width: group === "jbw-dealer" ? 250 : 300,
      renderCell: (param) => {
        const locationId = param.row.locationId;
        const tip = param.row.dupicateAgreementIds?.join(",");
        return (
          <>
            {locationId ? (
              <>
                <Typography
                  variant="subtitl1"
                  component="span"
                  sx={{
                    wordWrap: "break-word",
                  }}
                  whiteSpace="normal"
                >
                  {param.row.street ? `${param.row.street},` : ""}
                  {param.row.unit ? `${param.row.unit},` : ""}
                  {param.row.city ? `${param.row.city},` : ""}
                  {param.row.state ? `${param.row.state},` : ""}
                  {param.row.zip}.
                  {param.row.hasDuplicateLocation === 1 ? (
                    <Tooltip
                      title={
                        <div style={{ whiteSpace: "pre-line" }}>
                          Agreements: {tip}
                        </div>
                      }
                    >
                      <InfoRoundedIcon
                        style={{ fill: "#ffc400" }}
                        fontSize="small"
                      />
                    </Tooltip>
                  ) : (
                    ""
                  )}
                </Typography>
              </>
            ) : (
              ""
            )}
          </>
        );
      },
      sortable: false,
      headerAlign: "center",
      align: "left",
    },
    {
      field: "productCode",
      headerName: "Product Code",
      width: 130,
      sortable: false,
      filterable: false,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "pdfUrl",
      headerName: "Agreement Link",
      width: group === "jbw-dealer" ? 200 : 150,
      sortable: false,
      filterable: false,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "actions",
      //type: "actions",
      headerName: "Action",
      //cellClassName: "actions",
      width: 200,
      headerAlign: "center",
      align: "center",
      filterable: false,
      disableExport: true,
      renderCell: (params) => {
        const locationId = params.row.locationId;
        return (
          <>
            {locationId ? (
              <>
                <Button
                  startIcon={<AssignmentTurnedInIcon />}
                  color="success"
                  onClick={handleApprove(params)}
                >
                  Approve
                </Button>
                <Button
                  startIcon={<ContentPasteOffIcon />}
                  color="warning"
                  onClick={handleDecline(params)}
                >
                  Decline
                </Button>
              </>
            ) : (
              <Button
                startIcon={<AddLocationIcon />}
                color="primary"
                onClick={handleOpenLocationDialog(params)}
              >
                Map Location
              </Button>
            )}
          </>
        );
      },
      sortable: false,
    },
    {
      field: "jbwCustomerName",
      headerName: "JBW CustomerName",
      width: 150,
      sortable: false,
      filterable: false,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "jbwPhoneNumber",
      headerName: "JBW PhoneNumber",
      width: 150,
      sortable: false,
      filterable: false,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "jbwEmail",
      headerName: "JBW Email",
      width: 150,
      sortable: false,
      filterable: false,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "jbwAddressLine1",
      headerName: "JBW Address Line1",
      width: 150,
      sortable: false,
      filterable: false,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "jbwAddressLine2",
      headerName: "JBW Address Line2",
      width: 150,
      sortable: false,
      filterable: false,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "jbwCity",
      headerName: "JBW City",
      width: 150,
      sortable: false,
      filterable: false,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "jbwState",
      headerName: "JBW State",
      width: 150,
      sortable: false,
      filterable: false,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "jbwZip",
      headerName: "JBW Zip",
      width: 150,
      sortable: false,
      filterable: false,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "jbwPurchaseDate",
      headerName: "JBW Purchase Date",
      width: 150,
      sortable: false,
      filterable: false,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "locationId",
      headerName: "location Id",
      width: 150,
      sortable: false,
      headerAlign: "center",
      align: "center",
    },
  ];

  const handleApprove = (param) => async () => {
    console.log("Agreement Approve", param);
    setLoading(true);
    const response = await approveAgreements([param.row.id], user.name);
    console.log("approve response", response);
    if (response.data) {
      setLoading(false);
      fetchData(paginationModel);
    }
  };

  const handleDecline = (param) => () => {
    console.log("Agreement decline", param);
    setOpenDialog(true);
    setSelectedAgreement(param.row);
  };
  const handleOpenLocationDialog = (param) => () => {
    console.log("selected Location for mapping", param.row);
    setOpenDialog(true);
    setSelectedAgreement(param.row);
  };
  const handleDialogClose = () => {
    setOpenDialog(false);
    setSelectedAgreement(null);
  };
  const handleGridRefresh = () => {
    fetchData(paginationModel);
    setOpenDialog(false);
    setSelectedAgreement(null);
  };
  const handleApproveAll = async () => {
    //console.log("Agreement Approve", approveState);
    // console.log("Agreement Approve String ", approveState.join(","));
    setLoading(true);
    const response = await approveAgreements(approveState, user.name);
    console.log("approve response", response);
    if (response.data) {
      setLoading(false);
      fetchData(paginationModel);
    }
  };

  const getSelectedRowsToExport = ({ apiRef }) => {
    const selectedRowIds = selectedGridRowsSelector(apiRef);
    if (selectedRowIds.size > 0) {
      return Array.from(selectedRowIds.keys());
    }

    return gridFilteredSortedRowIdsSelector(apiRef);
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <Button
          variant="contained"
          color="secondary"
          disabled={!approveState.length}
          sx={{ mb: 1 }}
          onClick={handleApproveAll}
        >
          Approve All
        </Button>

        <GridToolbarFilterButton />
        <GridToolbarExport
          csvOptions={{ allColumns: true }}
          printOptions={{ disableToolbarButton: true }}
          getRowsToExport={getSelectedRowsToExport}
        />
      </GridToolbarContainer>
    );
  }

  const columnVisibilityModel = useMemo(() => {
    return {
      dealerId: group === "jbw-dealer" ? false : true,
      jbwCustomerName: false,
      jbwPhoneNumber: false,
      jbwEmail: false,
      jbwAddressLine1: false,
      jbwAddressLine2: false,
      jbwCity: false,
      jbwState: false,
      jbwZip: false,
      jbwPurchaseDate: false,
      locationId: false,
    };
  });

  return (
    <>
      <STLocationSearchPopup
        dialogOpen={openDialog}
        agreement={selectedAgreement}
        onClose={handleDialogClose}
        onGridRefresh={handleGridRefresh}
      />
      <Stack>
        {/* <Paper elevation={0}>
          <Button
            variant="contained"
            color="secondary"
            disabled={!approveState.length}
            sx={{ mb: 1 }}
            onClick={handleApproveAll}
          >
            Approve All
          </Button>
        </Paper> */}
        <Paper elevation={0}>
          <StyledDataGrid
            autoHeight
            pagination
            paginationMode="server"
            getRowClassName={(params) =>
              `super-app-theme--${
                params.row.hasDuplicateLocation === 1 ? "Open" : ""
              }`
            }
            checkboxSelection
            rows={pageState.rows}
            rowCount={pageState.total}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            rowsPerPageOptions={[50]}
            loading={loading}
            pageSizeOptions={[50]}
            isRowSelectable={(params) => params.row.locationId}
            onRowSelectionModelChange={(newSelectionModel) => {
              console.log("Selection Model ::", newSelectionModel);
              setApproveStatee(newSelectionModel);
            }}
            // onRowSelectionModelChange={(newRowSelectionModel) => {
            //   setRowSelectionModel(newRowSelectionModel);
            // }}
            // rowSelectionModel={rowSelectionModel}
            columns={columns}
            initialState={{
              pagination: { paginationModel: { paginationModel } },
            }}
            disableColumnMenu={true}
            slots={{
              toolbar: CustomToolbar,
              loadingOverlay: LinearProgress,
            }}
            columnVisibilityModel={columnVisibilityModel}
          />
        </Paper>
      </Stack>
    </>
  );
};

export default AgreementSyncGrid;
